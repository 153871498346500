#copilot-compare-page-top {
  margin: 46px auto 13px auto;

  #site-logo > a{
    text-decoration: none;
  }

  #site-logo > a > .logo-img {
    background-image: url(/svg/copilot-compare-logo.svg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 52px;
    width: 290px;
    display: inline-block;
    position: relative;
    vertical-align: middle;

    .beta-text{
      position: relative;
      bottom: -27px;
      text-align: right;
      font-family: TimesNewRomanPSMT;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: normal;
      color: #828486;
    }
  }

  .share-icon-container{
    position: relative;
    top: -1.5px;
    display: inline-block;
    .share-icon{
      background-image: url(/svg/icon-share-large.svg);
      width: 25px;
      height: 35px;
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      filter: invert(48%) sepia(0%) saturate(1259%) hue-rotate(132deg) brightness(30%) contrast(69%);
      vertical-align: sub;
      margin-left: 26px;
      margin-bottom: 2px;
    }

    .white-modal{
      display: none;
      background: white;
      position: absolute;
      top: -1px;
      right: -1px;

      border-radius: 8px;
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
      z-index: 10;
      padding-bottom: 17px;

      min-width: 250px;

      .share-header{
        padding: 20px 20px 0px 17px;

        .copy{
          font-family: "Geomanist";
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: rgba(26, 26, 26, 0.65);
          display: inline-block;
        }
        .close{
          display: inline-block;
          margin-left: 16px;
          margin-top: -2px;
          vertical-align: middle;
          width: 17px;
          height: 17px;
          background-size: contain;
          background-repeat: no-repeat;
          color: black;
          opacity: 1;
          font-size: 27px;
          font-weight: lighter;
        }
      }

      .icons {
        padding-top: 20px;
        margin-left: 21px;

        .icon {
          padding-bottom: 10px;

          .sharing-icon {
            display: inline-block;
            padding-right: 33px;

            &.email{
              background-image: url(/img/envelope.png);
              width: 21px;
              height: 14px;
              background-size: contain;
              background-repeat: no-repeat;
              display: inline-block;
            }

            &.text{
              background-image: url(/img/text-bubble.png);
              width: 22px;
              height: 18px;
              background-size: contain;
              background-repeat: no-repeat;
              display: inline-block;
              vertical-align: middle;
            }
          }

          .share-text-link{
            color: #F6610C;
            font-family: "Geomanist";
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            display: inline-block;
            vertical-align: text-bottom;

            &:hover{
              text-decoration: underline;
            }
          }

          .share-icon-cta {
            display: inline-block;
            color: #F6610C;
          }
        }
      }
    }
  }
}
#copilot_compare_form{
  font-family: "Geomanist";

  .header-cta {
    margin: 0 auto;
    max-width: 363px;
    text-align: center;

    .action-button {
      width: 100%;

      font-size: 18px;
      border-radius: 8px;
      border: solid 1px #f6610c;
      background-color: #f6610c;

      &:focus {
        outline: none;
      }
    }
  }

  .header-section{
    padding: 25px;
    padding-bottom: 0px;

    .header-title{
      font-family: "Geomanist";
      font-size: 26px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: #000000;
      text-align: center;

    }
  }

  .steps-container{

    .step{
      position: relative;

      &.border-me{
        border-bottom: 1px solid #e4e4e4;
      }

      &.prices{
        max-width: 375px;
        margin: 0 auto;
      }

      &.icons{
        padding-top: 21px;
        padding-bottom: 21px;
        background-color: white;
      }

      &.THE-HAND{
        padding-top: 44px;
        position: relative;
      }

      .step-header{
        padding: 25px;
        .step-count{
          display: inline-block;
          vertical-align: top;
          background-color: #575757;
          border-radius: 50%;

          width: 48px;
          height: 48px;

          text-align: center;
          color: white;
          line-height: 2.1;

          font-family: "Geomanist-medium";
          font-size: 23px;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
        }
        .step-copy{
          width: calc(100% - 70px);
          margin-left: 15px;
          display: inline-block;

          font-family: "Geomanist";
          font-size: 22px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.45;
          letter-spacing: normal;
          color: #000000;
        }
      }

      .step-content{
        position: relative;
        padding: 0px 17px;

        .quality-container{
          text-align: right;
          margin-top: 40px;
          margin-bottom: 20px;
          margin-right: 15px;

          .quality-container-title{
            font-family: "Geomanist-book";
            font-size: 19px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.95;
            letter-spacing: normal;
            color: #1a1a1a;
          }

          .quality-icons{
            margin-top: 12px;
            .quality-star{
              background-image: url(/svg/star-full.svg);
              width: 40px;
              height: 40px;
              background-size: contain;
              background-repeat: no-repeat;
              display: inline-block;
              filter: invert(20%) sepia(99%) saturate(4395%) hue-rotate(326deg) brightness(104%) contrast(98%);              vertical-align: sub;
              margin-left: 3px;
              margin-right: 3px;

            }
          }
        }

        .prices-container{
          position: relative;
          height: 200px; //includes the height of the bubbles and the text below it since the text below occludes the tails

          .price-bubble{
            position: absolute;
            top: 24px;

            background-color: #fef0e7;
            border-radius: 8px;

            width: 157px;

            padding: 17px 0px;

            text-align: center;

            &.left{
              left: 0px;

              border-bottom-left-radius: 0px;
              background-color: rgba(0, 0, 0, 0.05);

              .bubble-sub-copy{
                color: black;
              }

              .tail{
                left: 0px;
                background-color: rgba(0, 0, 0, 0.05);
              }
            }

            &.right{
              right: 0px;

              border-bottom-right-radius: 0px;

              .bubble-sub-copy{
                color: #f6610c;
              }
              .bubble-copy{
                color: #f6610C;
              }
              .tail{
                right: 0px;
              }
            }

            &.extra-height{
              top: 0px;
            }

            .tail{ // really just a box that the border radii of the next element occludes to make it look like a tail
              width: 80px;
              height: 65px;
              background-color: #fef0e7;
              position: absolute;
              bottom: -65px;
            }

            .bubble-copy{
              font-family: "Geomanist";
              font-size: 32px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 22px;
              letter-spacing: normal;
              text-align: center;
              color: rgba(26, 26, 26, 0.65);

              margin-bottom: 12px;
              margin-top: 12px;
            }

            .bubble-sub-copy{
              font-family: "Geomanist";
              font-size: 24px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 22px;
              letter-spacing: normal;
              text-align: center;
              color: rgba(26, 26, 26, 0.65);
            }
          }
        }
        .white-copy-section{
          position: absolute;

          border-top-left-radius: 75px;
          border-top-right-radius: 75px;
          background: white;

          line-height: 30px;
          color: black;
          text-align: center;

          height: 80px;

          top: 124px;
          left: 15px;
          width: calc(100% - 28px);

          .bubble-special-copy{
            font-family: "Geomanist-book";
            font-size: 32px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 37px;
            letter-spacing: normal;
            text-align: center;
            color: #f6610c;
          }

          .section-copy{
            width: calc(100% - 50px);
            min-width: 200px;
            margin: 10px auto;

            font-family: "Geomanist";
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #000000;
          }
        }

        .icons-container{
          padding: 41px 6px 0px;
          text-align: center;

          .icon-container{
            display: inline-block;
            width: 32%;
            text-align: center;

            .icon{
              width: 60px;
              height: 60px;

              margin: 0px auto 25px auto;

              background-size: contain;
              background-repeat: no-repeat;
              display: inline-block;
              vertical-align: middle;

              filter: invert(48%) sepia(0%) saturate(1259%) hue-rotate(132deg) brightness(30%) contrast(69%);

              &.sound{
                background-image: url("/svg/equipment-sound-system.svg");
              }

              &.bluetooth{
                background-image: url("/svg/equipment-bluetooth.svg");
              }

              &.auxiliary{
                background-image: url("/svg/equipment-auxiliary.svg");
              }
            }

            .icon-copy{
              font-family: "Geomanist";
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.57;
              letter-spacing: normal;
              text-align: center;
              color: rgba(26, 26, 26, 0.65);
            }
          }
        }
      }

      .car-photos{
        position: relative;
        height: 110px;
        margin: 5px 0px 36px;

        .car-photo{
          position: absolute;
          background-image: url("/img/copilot-compare-car-image.png");
          object-fit: cover;
          background-repeat: no-repeat;
          height: 110px;
          width: 176px;
          display: inline-block;
          vertical-align: middle;

          &.left{
            left: 0px;
          }

          &.right{
            right: 0px;
            transform: scaleX(-1); // effectively flips the image in the opposite direction saving us from loading an additional img
          }
        }
      }

      .THE-HAND-HIMSELF{
        background-image: url("/img/cc-THE-HAND.webp"), url("/img/cc-THE-HAND.jp2");
        background-size: contain;
        background-repeat: no-repeat;
        height: 422px;
        width: 100%;
        z-index: 2;
        position: relative;
      }

      .THE-HANDS-DRAMATIC-GRADIENT{
        position:absolute;

        bottom: 0px;
        left: 0px;

        height: 60px;
        width: 100vw;

        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(150,150,150,0.4) 0%, rgba(255,255,255,0) 100%, white 100%);
        z-index: 1;
      }
    }
  }



  .form-section {
    padding: 25px;
    margin-top: 5px;
    padding-bottom: 32px;
    margin-bottom: 64px;

    .form-title{
      font-family: "Geomanist";
      font-size: 32px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #000000;
    }

    .styled-textbox {
      margin-bottom: 6px;

      & > input:disabled {
        border-color: #d7d7d7;
        background-color: #f5f5f5;
      }

      & > input::-webkit-outer-spin-button,
      & > input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      & > input[type=number] {
        -moz-appearance: textfield;
      }

      & > input {
        font-family: Geomanist;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: rgba(26, 26, 26, 0.5);

        width: 100%;
        max-width: 100%;

        border-radius: 8px;
        border: solid 1px rgba(0, 0, 0, 0.14);

        padding: 16px;

        &::placeholder {
          font-family: Geomanist;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: rgba(26, 26, 26, 0.5);
        }

        &:-ms-input-placeholder {
          font-family: Geomanist;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: rgba(26, 26, 26, 0.5);
        }

        &::-ms-input-placeholder {
          font-family: Geomanist;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: rgba(26, 26, 26, 0.5);
        }
      }
    }

    .styled-select {
      & > select:disabled {
        background-color: #f5f5f5;
        border-color: #d7d7d7;
        opacity: 1;
      }

      & > select:disabled + .selected-item {
        background-color: #f5f5f5;
      }

      & > select {
        padding: 16px;
        //color: transparent;
        border-radius: 8px;
        border: solid 1px rgba(0, 0, 0, 0.14);


        & > option:first-child {
          font-family: "Geomanist";
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: rgba(26, 26, 26, 0.5);
        }

      }

      & > .styled-select-arrow-container {
        position: absolute;
        width: 17px;
        height: 8px;
        top: 21px;
        right: 10px;
        overflow: hidden;

        & > .styled-select-arrow {
          position: absolute;
          top: -8px;
          left: 0;
          box-sizing: border-box;
          width: 12px;
          height: 12px;
          border: 2px solid #000000;
          transform: rotate(45deg);
          background: #fff;
        }
      }

      &::after {
        display: none;
        content: initial;
        font-family: initial;
      }

      .selected-item {
        width: 90%;
        position: absolute;
        top: 12px;
        left: 16px;
        background: white;

        pointer-events: none;

        color: rgba(26, 26, 26, 0.5);
        font-family: Geomanist;
        font-size: 16px;

      }
    }

    .action-button {
      width: 100%;
      padding-top: 14px;
      padding-bottom: 14px;
      border: none;
      border-radius: 8px;
      font-size: 18px;
    }
  }
}

#ml_testimonials {
  height: fit-content;

  #bar-steps-iterator{
    width: 183px;
    margin: 30px auto;
  }

  .testimonial-title{
    font-family: "Geomanist";
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    color: #1a1a1a;

    padding: 0px 33px;
  }
  &.copilotcompare-testimonials {
    padding: 66px 0px 82px;
    background-color: #f5f5f5;

    &.landing {
      background-color: unset;
      padding: 50px 0px 0px;

     .ml-pain-point {
       margin-top: 0;
     }
    }

    .ml-pain-point.no-color{
      height: 470px;
    }

    .ml-pain-point {
      margin-top: 47px;

      .pain-point-icon{
        &.mathew-hamilton{
          background-image: url("/img/mathew-hamilton-icon.png");
          background-size: contain;
          background-repeat: no-repeat;
          vertical-align: middle;

          border-radius: 50%;

          margin-bottom: 32px;
        }
      }

      .quotation-icon{
        background-image: url("/svg/double-quotes.svg");
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: middle;

        filter: brightness(0) saturate(100%);
        opacity: 0.5;
      }

      blockquote p {
        font-family: "Geomanist";
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: -0.2px;
        color: #1a1a1a;
      }

      blockquote footer {
        opacity: 0.6;
        font-family: "Geomanist-book";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1a1a1a;

        &:before{
          content: '';
          margin-right: 0px;
        }
      }
    }
  }
}


@media (min-width: 768px){
  #copilot_compare_form {
    .header-section{
      .header-title{
        opacity: 0.5;
      }
      .header-cta{
        margin: 0 auto;
      }
    }

    .steps-container{
      .step{
        .step-header{
          .step-count{
            vertical-align: baseline;
          }
        }

        &.prices{
          max-width: 600px;
          .step-content{
            max-width: 375px;
            margin: 0 auto;
          }
          .car-photos{
            max-width: 375px;
            margin: 0 auto 41px;
          }
        }

        &.icons{
          .step-header{max-width: 600px; margin: 0 auto;}
          .step-content{max-width: 600px; margin: 0 auto;}
        }

        &.THE-HAND{
          position: relative;

          .step-header{
            position: relative;
            left: -100px;
            top: 125px;
            z-index: 2;
            width: 375px;
            margin: 0 auto;
            .step-copy{
              width: 250px;
            }
          }

          .THE-HAND-HIMSELF{
            position: relative;
            left: 200px;
            width: 375px;
            margin: 0 auto;
          }
        }
      }
    }

    .form-section{
      text-align: center;
      .form-title { text-align: center; opacity: 0.5; }
      .styled-select{
        display: inline-block;
        width: 27%;
        margin-right: 10px;
        .selected-item{ text-align: left; }
      }
      .styled-textbox{
        width: 120px;
        display: inline-block;
      }
      .form-cta-cc{
        width: 120px;
        display: inline-block;
        margin-left: 10px;
        vertical-align: bottom;
        margin-bottom: 10px;

        .action-button{
          margin-top: 0px;
        }
      }
    }

  }
  #ml_testimonials {
    &.copilotcompare-testimonials{
      padding: 76px 10%;

      .testimonial-title{
        text-align: center;
      }



      .ml-pain-point{
        height: 304px;
        .pain-point-icon{
          margin: 0 auto 32px;
          display: block;
        }
      }

      #bar-steps-iterator{
        width: 80%;
        margin: 0 auto;
      }
    }


  }
}