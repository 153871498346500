.widget_title {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin-bottom: 24px;
}

.widget {
  + .widget {
    margin-top: 40px;
  }
  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 5px 0 5px 25px;
      position: relative;

      &:before {
        font-family: "Font Awesome 5 Free";
        font-weight: 300;
        font-size: 0.8em;
        position: absolute;
        top: 7px;
        left: 0;
        color: $mainColor;
      }

      &:first-child {
        padding-top: 0;

        &:before {
          top: 2px;
        }
      }

      &:last-child {
        padding-bottom: 0;
      }

      + li {
        border-top: 1px solid $borderColor;
      }
    }
  }
}

.widget-recent {
  li {
    &:before {
      content: "\f1ea";
    }
  }
}

.widget-categories {
  ul li {
    &:before {
      content: "\f054";
      font-weight: 700;
      left: 5px;
    }
  }
}

.widget-taglist {
  .widget-content {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
  }
}

.post-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;

  i {
    margin-right: 5px;
    color: $darkGreyColor;
  }
}

.tag-list {
  display: inline-flex;
}

.widget-taglist .widget-content > a,
.post-tags .tag-list > a {
  padding: 0 10px;
  margin: 5px;
  color: $darkGreyColor;
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  background-color: $backgroundColor;
  border-radius: $borderRadius;

  &:hover {
    color: #fff;
    background-color: $mainColor;
  }
}

.widget-social {
  .social_block {
    border: none;
    padding: 0;
    text-align: left;
  }
}

.widget-cta {
  .col-lg-3 & {
    .post_action {
      padding: 20px;
    }
  }
}
