.lease-supply-landing {
    box-sizing: border-box;
    height: 550px;
    margin: 0 auto !important;
    padding: 20px 0 60px;
    width: 960px;
    &-tools {
        box-sizing: border-box;
        display: inline-block;
        margin-right: 35px;
        vertical-align: top;
        width: 460px;
        &-text-container {
            margin: 0 auto;
            .lease-hpc-tool {
                &-title {
                    font-size: 24px;
                    line-height: 30px;
                    margin-bottom: 16px;
                    font-weight: bold;
                }
                &-intro {
                    color: #455266;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 16px;
                }
                &-points {
                    &-list {
                        padding: 0px 15px;
                        list-style-type: unset;
                        li {
                            margin: 8px 0px;
                            line-height: 24px;
                        }
                    }
                }
            }
            .ml-form-container {
                .lease-hpc-cta-container {
                    text-align: left;
                    margin: 15px 0px 0px 0px;
                    &-title {
                        font-size: 20px;
                        font-weight: bold;
                        line-height: 22px;
                        margin-bottom: 5px;
                    }
                    .ml-button-container {
                        margin: 8px auto;
                        width: 100%;
                    }
                }
            }
        }
    }
    .trade-in-vector {
        width: 460px;
        height: 100% !important;
        background-image: url(/navigator/img/img-curated-search@2x.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: inline-block;
        box-sizing: border-box;
    }
}

.lease-hpc-nav-cta-container {
    padding: 34px 23px 44px;

    h2 {
        font-size: 22px;
        font-style: normal;
        font-weight: normal;
        line-height: 32px;
    }

    p {
        color: rgba(0, 0, 0, .5);
        font-size: 18px;
        line-height: 32px;
        padding: 22px 0 34px;
    }
}

.button-container {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 48px;
    cursor: pointer;
    transition: opacity 0.1s, transform ease-in 0.1s;
    user-select: none;
    button {
        background-color: $ml-mainOrange;
        border-radius: 8px;
        border: none;
        color: #ffffff;
        font-family: GeomanistBook;
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        font-stretch: normal;
        letter-spacing: normal;
        text-transform: none;
        display: table-cell;
        width: 100%;
        height: 100%;
        text-align: center;
        vertical-align: middle;
    }
}

//Tablet - Everything Below Desktop; this was designed for Desktop and then Mobile - hence the change in media queries
//Tablet is 768 to 1000px
@media (max-width: 999px){
    .lease-supply-landing {
        width: 690px;
        margin: 0 auto 16px !important;
        &-tools {
            width: 330px;
            margin-right: 25px;
            &-text-container {
            }
        }
        .trade-in-vector {
            width: 330px;
            height: 100%;
        }
    }
}


//Mobile - Everything Below Tablet; this was designed for Desktop and then Mobile - hence the change in media queries
//Mobile is everything below 768px
@media (max-width: 767px) {
    .lease-supply-landing {
        width: auto;
        margin: 0 auto 60px !important;
        height: 85vh;
        &-tools {
            width: 100%;
            margin-right: unset;
            &-text-container {
                margin: 0px 16px;
            }
        }
        .trade-in-vector {
            width: 100%;
            height: 200px !important;
        }
    }
}