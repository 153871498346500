.subpage_top {
  background-color: $darkColoredColor;

  // #site_header {
  //   background-color: rgba(#000, 0.2);
  // }
}

#site_header {
  padding: 7px 0;

  .navbar {
    padding: 0;
  }

  &.ml{
    background-color: $ml_mainGray;
  }
}

.site_logo {
  img {
    width: 100px;
    vertical-align: text-top;
  }

  .special-svg-logo{
    text-decoration: none;
  }

  .special-svg-logo > .copilot-logo-with-tag-svg {
    background-image: url(/svg/CoPilot_logo_with_tag.svg);
    background-size: 100px;
    background-repeat: no-repeat;
    height: 60px;
    width: 120px;
    margin-top: -26px;
    display: inline-block;
    position: relative;
    vertical-align: text-top;
  }
}

.abs_top {
  position: relative;
  z-index: 10;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}


#header {
  background:#fff;
  border-bottom:1px solid var(--header-orange);
  height:64px;
  padding:0 25px;
}
#header a.logo-link {
  display:inline-block;
  margin-top:14px
}
#header div.navigation-options {
  display:inline-block;
  float:right;
  padding-top:20px;
  text-align:right;
  vertical-align:top;
  width: calc(100% - 150px)
}
#header div.navigation-options a {
  color:$darkGreyColor;
  display:inline-block;
  font-family: 'Geomanist';
  font-size:16px;
  font-weight:300;
  line-height:20px;
  padding:0 25px;
  text-align:center;
  text-decoration:none;
  vertical-align:top;
}
#header div.navigation-options a.login {
  padding-right:0;
}
#header div.navigation-options a:hover {
  color:var(--header-orange);
}

div.mobile-navigation-options,
div.mobile-navigation-options.show {
  background:#fff;
  box-shadow:0 0 3px rgba(0,0,0,0.2);
  display:none;
  padding:10px 0;
}
div.mobile-navigation-options a {
  color:$darkGreyColor;
  display:block;
  font-family: 'Geomanist';
  font-size:16px;
  font-weight:300;
  line-height:20px;
  padding:10px 25px;
  text-decoration:none;
}
div.mobile-navigation-options a:hover {
  color:var(--header-orange);
}
#mobile-menu-toggle{
  color: #000;
  display:none;
  font-size: 26px;
  margin-right:20px;
  position: relative;
  text-decoration: none;
  top: 5px;
}

#make-dropdown {
  background:#fff;
  border-bottom:1px solid #e6e6e6;
  border-left:1px solid #e6e6e6;
  display:none;
  padding:25px;
  position:absolute;
  right:0;
  top:64px;
  width:80%;
  z-index:3;
}

#make-dropdown div {
  display:flex;
  flex-wrap:wrap;
}

#make-dropdown a {
  color:$darkGreyColor;
  display:block;
  font-family: "Geomanist"; 
  font-size:16px;
  font-weight:300;    
  padding:10px 0;
  text-decoration:none;
  width:calc(100%/7);
}

#make-dropdown a:hover {
  color:var(--header-orange);
}

#make-drop-down-toggle i,
#mobile-make-drop-down-toggle i {
  font-size:12px;
  margin-left:5px;
  position:relative;
  top:-2px;
}

