

#srp_layout {
  display: flex;
}
#srp_filter_wrapper {
  background:#fff;
  width:320px
}
#srp_results_wrapper {
  background:#f8f8f8;
  flex-grow:1;
  padding-bottom:15px;
  width: calc(100% - 330px);
}
#valet_srp_results,
div.valet_srp_controls {
  border-left:1px solid #e6e6e6;  
}
div.valet_srp_controls{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 5px;
}
a.valet_srp_item {
  display: block;
  text-decoration: none;
  color: $fontColor;
}
a.valet_srp_item_none {
  display: inline-block;
  margin: 0 20px;
}
div.valet_item_media {
  float:left;
  position: relative;
  width:225px;
  max-width: 225px;
  // height:203px;
}
.valet_item_image {
  background-size: cover;
  background-position: center;
  padding-bottom: 75%; 
  // height:100%; 
}
a.valet_item_image {
  display: block;
  overflow:hidden;
}
.valet_item_content {
  display:flex;
  float:left;
  min-height:168px;
  position:relative;
  width:calc(100% - 225px);
}
div.valet_srp_controls {
  margin:0;
  padding:25px;
}
#srp_results_wrapper .valet_srp_header {
  background:#fff;
  border-left:1px solid #e6e6e6;
  border-bottom:1px solid #e6e6e6;
  padding:25px;
}

#srp_results_wrapper .valet_srp_header h1 {
font-family:'Geomanist';
  font-size:20px;
  font-weight:300;
  color:$darkGreyColor;
  margin-bottom:0;
}
#srp_results_wrapper .valet_srp_header h1 span {
  font-weight:700;
  padding-right:5px;
}

a.valet_srp_item,
div.valet_srp_item {
  background:#fff;
  border:1px solid #e6e6e6;
  border-bottom-left-radius:10px;
  border-top-left-radius:10px;
  box-shadow:0 8px 16px rgba(0,0,0,0.07);
  margin:0 25px 25px;
  overflow:hidden;
}

.valet_item_content span.condition,
.valet_item_content span.first-take-header,
.valet_item_content span.car-title,
.valet_item_content span.posted-days,
.valet-package-content span.valet-packages-header {
  color:rgba(26,26,26, 0.65);
  display:block;
  font-family: "Geomanist";
  font-size:14px;
  font-weight:300;
  padding-top:10px;
}

.valet_item_content span.condition {
  padding-top:0;
  line-height: 14px;
  // padding-bottom: 7px
}



.valet_item_content span.first-take-price {
  color:#000;
  display:block;
  font-family: "Geomanist";
  font-size:14px;
  font-weight:500; 
}

.valet_item_content span.first-take-price span.package-summary{
  display:block;
  float:left;
  line-height:20px;
  margin:0 0 0 35px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  width:calc(100% - 130px);
}
.valet_item_content span.first-take-price a.expand-packages {
  color:#4D6EB5;
  float:left;
  display:inline-block;
  line-height:20px;
  text-decoration:none;
}


.valet_item_content span.posted-days {
  line-height:14px;
  padding-top:0
}
.valet_item_content span.price {
  color:#161616;
  display:block;
  font-family: "Geomanist-book";
  font-size:16px;
  font-weight:500;
  line-height:16px
}
.valet_item_content span.dealer {
  color:#161616;
  display:block;
  font-family: "Geomanist";
  font-size:16px;
  font-weight:400;
  padding-bottom:5px;
  // padding-top:10px;
}
.valet_item_content span.price em.old-price {
  color:#6a6a6a;
  font-family: "Geomanist";
  font-size:14px;
  font-style:normal;
  font-weight:400;
  padding-left:4px;
  text-decoration:line-through;
}

.valet_item_content span.price b {
  display:inline-block;
  padding:0 5px;
}

.valet_item_details {
  border-right:1px solid #eee;
  padding-left: 25px;
  margin:17px 0;
  width:45%
}
.valet_item_advice {
 padding-left:15px;
  width:55%;
  
}

.valet_item_content span em.highlight {
  background:var(--header-orange);
  border-radius:50%;
  color:#fff;
  display:inline-block;
  height:24px;
  line-height:24px;
  margin-right:10px;
  text-align:center;
  width:24px;
}
.valet_item_content span em.highlight i {font-size:14px;}
.valet_item_content span em.highlight svg {height:14px}

.valet_item_badge {
  background:rgba(0,0,0,0.7);
  border-radius:5px;
  color:#fff;
  font-family: "Geomanist";
  font-size:12px;
  font-weight:400;
  left:10px;
  padding:0 12px;
  position:absolute;
  top:10px;
}

.valet_item_badge.second-badge {
  left:116px;
}

.valet_item_badge svg {margin-right:5px; height:14px}

.valet_item_content span.valet-package-toggle {
  bottom:10px;
  color:#8a8a8a;
  display:block;
  height:20px;
  line-height:20px;
  position:absolute;
  right:15px;
  text-align:center;
  width:20px;
}
.valet_item_content span.valet-package-toggle i.fa-chevron-up,
.valet_item_content span.valet-package-toggle.open i.fa-chevron-down {display:none}
.valet_item_content span.valet-package-toggle.open i.fa-chevron-up {display:block}
.valet-package-content {
  border-top:1px solid #e6e6e6;
  clear:left;
  display:none;
  padding:0 25px 20px;
  position:relative;
}
a.close-packages-section {
  color:#000;
  font-family: "Roboto";
  position:absolute;
  right:18px;
  text-decoration:none;
  top:10px;
}
.valet-packages-wrapper {
  display:flex;
  flex-wrap: wrap;
}

.valet-packages-wrapper .valet-package {
  color:#000;
  padding-right:25px;
  width:25%;
}

.valet-packages-wrapper .valet-package span.package-description{
  display:block;
  font-family: "Geomanist-book";
  font-size: 16px;
  padding:10px 0 15px;
  line-height:20px;
}

.valet-packages-wrapper .valet-package span.package-market-value {
  display:block;
  font-family: "Geomanist";
  font-size: 14px;
  font-weight: 400;
  padding:0 0 10px;
  line-height:14px; 
}
.valet-packages-wrapper .valet-package span.package-msrp-value {
  color:#6a6a6a;
  display:block;
  font-size:12px;
  font-style:normal;
  font-weight:300;
  padding:0 0 10px;
  line-height:16px; 
}
.valet-packages-wrapper .valet-package span.package-item {
  display:list-item;
  font-family: "Geomanist";
  font-size:14px;
  font-weight:300;
  line-height:20px;
  list-style: none; 
  margin-left:15px;
  padding:3px 0;
}

.valet-packages-wrapper .valet-package span.package-item::before {
  content: "\2022"; 
  color:rgba(26,26,26, 0.65);
  font-weight: bold; 
  display: inline-block;
  width: 1em; 
  margin-left: -1em; 
}

.valet-packages-wrapper .valet-package span.package-item.hidden {
  display:none;
}
.valet-packages-wrapper .valet-package span.package-item.hidden.show {
  display:list-item;
}
.valet-package-content span.show-more-packages {
  color:#4D6EB5;
  display:inline-block;
  font-family: "Geomanist";
  font-size: 14px;  
  padding:10px 0 0
}

#srp_filter_search {
  background:#fffaee;
  box-shadow:0 0 3px rgba(0,0,0,0.2);
  padding:25px;
}
#srp_filter_search h4,
#srp_filter_wrapper h4.filters {
  font-size: 20px;
  font-family: "Geomanist";
  font-weight: 400;
  color: #1a1a1a;
}
#srp_filter_wrapper h4.filters {
  margin-bottom:0;
  padding:25px 25px 0;
}
div.styled-select {
  margin-top:5px;
  margin-bottom:10px;
  position:relative;
}
div.styled-select::after {
  color:#000;
  content: "\f0dd";
  font-family:'FontAwesome';
  position:absolute;
  top:2px;
  right:10px;
}
div.styled-select.disabled::after {
  color:#8a8a8a
}
div.styled-select select,
#srp_filter_search input[type="text"] {
  display: block;
  font-family: "Geomanist";
  font-size: 14px;  
  font-weight: 400;
  color: #000;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;

}
div.styled-select select {
  border-radius:5px;
}
div.styled-select select:disabled {color:#bbbbbb}

#srp_filter_search input[type="text"] {
  padding-bottom:.6em;
}

div.styled-select select:hover {
  border-color: #ddd;
}
div.styled-select select:focus {
  border-color: #ccc;
}
div.styled-select select option {
  font-family: "Roboto";
  font-weight:normal;
}
div.styled-select select option[disabled] {
  display:none;
}

#srp_filter_search table {width:100%}
#srp_filter_search table td.to {
  color:#000;
  font-family: "Geomanist";
  font-size: 14px;  
  font-weight: 400;  
  padding:6px 15px 0;
  text-align:center;
  vertical-align:top;
}

#srp_filter_search table.equal td {
  width:50%;
  vertical-align:top;
}
#srp_filter_search table.equal td.to {
  width:0;
  padding:0 5px;
}
#srp_search_submit {
  background:var(--header-orange);
  border-radius:5px;
  color:#fff !important;
  display:block;
  font-family: "Geomanist";
  font-size: 14px;  
  font-weight: 400; 
  padding:6px 0 4px;  
  text-align:center;
  text-decoration:none;
}
.action-button {
  background:var(--header-orange);
  border-radius:5px;
  color:#fff !important;
  display:inline-block;
  font-family: "Geomanist";
  font-size: 14px;  
  font-weight: 400; 
  padding:6px 16px 4px;  
  text-align:center;
  text-decoration:none !important;
  cursor:pointer;
  margin-top: 10px;
  width: 152px;
}
#srp_search_submit.notready,
.action-button.disabled {
  opacity:0.3
}
/** filter styles */
h2.srp_display {
  font-family: "Geomanist";
  font-size: 40px;  
  font-weight: 700;
  padding:0;
}
div.filter_container {
  border-top:1px solid #e6e6e6; 
  padding:20px 25px;
}
#srp_filter_wrapper div.filter_container:nth-of-type(1) {
  border:0;
}
div.filter_container h3 {
  color:#131313;
  font-size: 16px;
  font-family: "Geomanist-book";
  font-weight: 300; 
  margin-bottom:15px; 
  position:relative
}
div.filter_container h3 em {
  font-family: "Geomanist";
  font-size:13px;
  font-style:normal;
  padding-right:15px;
  float:right;
  text-align:right;
}
div.filter_container h3.closed {
  margin-bottom:0
}
div.filter_container h3 a {
  color:#1a1a1a;
  text-decoration:none;
}
div.filter_container h3 a i {
  color:#8a8a8a;
  font-size:14px;
  position:absolute;
  right: -10px; 
  top: 3px; 
}
div.filter_container h3 a i.fa-chevron-down,
div.filter_container h3.closed a i.fa-chevron-up {
  display:none;
}
div.filter_container h3.closed a i.fa-chevron-down {display:block}
div.form-check  {
  padding-left:0
}
div.form-check.hidden {
  display:none;
}
div.form-check.hidden.show {
  display:block;
}
div.form-check label.form-check-label {
  color:#131313;
  cursor:pointer;
}
div.form-check label.form-check-label input {
  display:none;
}
div.form-check label.form-check-label i {
  color:#8a8a8a;
  font-size:24px;
  margin-right:5px;
}
div.form-check label.form-check-label i.fa-square-o {
  font-size: 26.5px;
  position: relative;
  top: 1.5px;
}
div.form-check label.form-check-label i.fa-check-square {
  color:var(--header-orange);
  line-height:28px;
  margin-right:5.25px;
}
div.form-check label.form-check-label i.fa-check-square,
div.form-check label.form-check-label input:checked + i.fa-square-o {display:none}
div.form-check label.form-check-label input:checked ~ i.fa-check-square {display:inline-block;}
div.form-check label.form-check-label span {
  display:inline-block;
  font-family: "Geomanist";
  font-size:14px;
  font-weight:500;
  position:relative;
  top:-3px;
}
div.form-check label.form-check-label span em {
  color: rgba(26,26,26,0.5);
  font-size:11px;
  font-style:normal;
  font-weight:300;
  position: relative;
  top: -2px;  
}
#srp_filters a.filter-options-more-toggle {
  color:#4D6EB5;
  font-size: 14px;
  font-family: "Geomanist";
  font-weight: 400;   
  text-decoration:none;
}
/** histogram styles */
#price-histogram {
  height:62px;
  position:relative;
  width:270px;
}
#price-histogram span {
  background:#D2DCEF;
  bottom:0;
  position:absolute;
  width:11px;
}
#price-histogram span.fade{
  opacity:0.3
}
/** no match p style */
p.no-matches {
  font-size: 20px;
  font-family: "Geomanist";
  font-weight: 400;
  padding:0 25px;
}
/** star rating filter styles */
p.star-rating-header {
  color:rgba(26,26,26, 0.5);
  font-size: 14px;
  font-family: "Geomanist";
  font-weight: 300;
}
div.star-rating {
  display:inline-block;
  overflow:hidden;
  transform: translate(22%, 0);
  width:185px;
}

div.star-rating input {
  display:none;
}
div.star-rating label {
  cursor:pointer; 
  float:right;
  width:37px;
}

div.star-rating.disabled label {
  cursor: default;
}
 
div.star-rating label i {
  color:var(--header-orange);
  font-size:32px;
  height:32px;
  float:left;
  margin:0 2.5px;
  padding:0;
  width:32px;
}

div.star-rating label i.fa-star {display:none;}
div.star-rating > input:not(:checked) + label:hover i.fa-star,
div.star-rating > input:not(:checked) + label:hover ~ label i.fa-star,
div.star-rating > input:checked ~ label i.fa-star,
div.star-rating > input:checked + label i.fa-star {
  display:block;
}
div.star-rating > input:not(:checked) + label:hover i.fa-star-o,
div.star-rating > input:not(:checked) + label:hover ~ label i.fa-star-o,
div.star-rating > input:checked ~ label i.fa-star-o,
div.star-rating > input:checked + label i.fa-star-o {
  display:none;
}

div.star-rating > input:not(:checked) + label.blank:hover i.fa-star,
div.star-rating > input:not(:checked) + label.blank:hover ~ label.blank i.fa-star{display:none;}

/** srp chip styles */
#valet_srp_chips {
  overflow:auto;
}
#valet_srp_chips span {
  border:1px solid #D8D8D8;
  border-radius:26px;
  color:#8c8c8c;
  display:block;
  float:left;
  font-size: 14px;
  font-family: "Geomanist";
  font-weight: 300;
  margin:10px 10px 0 0;  
  padding:5px 45px 5px 15px;
  position:relative;
  white-space:nowrap;
}

#valet_srp_chips #reset-filter-link {
  color:var(--header-orange);
  display:block;
  float:left;
  font-size: 16px;
  font-family: "Geomanist"; 
  padding:14px 10px 0;
  text-decoration:none;
}

#valet_srp_chips span a {
  background:#D8D8D8;
  border-radius:12px;
  color:#fff;
  height:24px;
  line-height:24px;
  position:absolute;
  right:10px;
  text-align:center;
  text-decoration:none;
  top:7px;
  width:24px;
}
#valet_srp_chips span a i {
  font-size:15px;
}
#valet_srp_chips span a:hover {
  background:#a8a8a8;
}

#tablet-filter-toggle,
#tablet-filter-close,
#tablet-filter-bg {
  display:none;
}

#results-list-cta-box {
  box-shadow: 0 8px 16px rgba(0,0,0,0.07);
  margin: 0 25px 25px;
  height:168px;  
}

#results-list-cta-box h5 {
  font-size:26px;
  padding:25px 0 0;
  text-align:center;
}

#results-list-cta-box .button {
display:block;
  margin:0 auto;
  width:242px;
}

#valet_inter_links {
  border-top:1px solid #e6e6e6;
  padding:25px;
}

#valet_inter_links h4 {
  text-align:center;
}