article.post {
  + article.post {
    margin-top: 30px;
  }
}

h2.post_title {
  margin-bottom: 0.7em;
}

.post_title {
  a {
    color: $darkGreyColor;
    text-decoration: none;

    &:hover {
      color: $mainColor;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.list_view {
  .post {
    .col-lg-5 + .col-lg-7 {
      margin-top: 20px;
    }

    @media (min-width: 992px) {
      .col-lg-5 + .col-lg-7 {
        margin-top: 0;
      }

      .row {
        align-items: center;
      }
    }

    + .post {
      &:before {
        content: "";
        display: block;
        height: 1px;
        margin: 30px 0;
        background-color: $borderColor;
      }
    }
  }
}

@media (min-width: 1200px) {
  .post_summary {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    max-height: 135px;
  }
}
