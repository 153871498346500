$mainColor: #ef4123;
$mainColor2: #f89626;
$fontColor: #6c6c6c;
$lightGray: #7F7F7F;
$darkGreyColor: #131313;
$darkColoredColor: #344944;
$borderColor: rgba(0, 0, 0, 0.1);
$backgroundColor: #f6f7f7;
// $backgroundColor: #efefef;
// $backgroundColor: #eee;

/* Dark section colors */
$dsFontColor: #b1b1b1;
$dsBorderColor: rgba(#fff, 0.35);

/* Price colors */
$greatDeal: #48c748;
$goodDeal: #48c748;
$fairDeal: #c6e043;
$potentiallyOverpriced: #ffdb26;
$overpriced: #e62048;

$borderRadius: 6px;


/* MetaLabs Design */
$ml_mainOrange: #f6610c;
$ml_mainWhite: #ffffff;
$ml_mainGray: #f9f9f9;

/*
//////////////////////////////////////////////////////////////
// imported from spacewhale, July 2021: colors, typography and effects
$white: #fff;
$black: #000;
$dark-grey: #455266; // Secondary titles; Body and paragraph text
$ml-grey8: rgba(26,26,26,0.3); // #bababa
$ml-black: #1a1a1a;
$original-brand-orange: #ef4123;
$ml-blue3: #e0e7f2;
@mixin color-dark-grey { color: $dark-grey; }
@mixin color-white { color: $white; }
@mixin background-white { background: $white; }

@mixin font-ml-heading-xx-large-book {
  font-family: GeomanistBook;
  font-weight: normal;
  font-style: normal;
  font-size: 48px;
  line-height: 58px;
  font-stretch: normal;
  letter-spacing: normal;
  text-transform: none;  
}
@mixin font-ml-heading-x-large {
  font-family: GeomanistRegular;
  font-weight: normal;
  font-style: normal;
  font-size: 32px;
  line-height: 38px;
  font-stretch: normal;
  letter-spacing: normal;
  text-transform: none;  
}
@mixin font-ml-heading-x-large-book {
  font-family: GeomanistBook;
  font-weight: normal;
  font-style: normal;
  font-size: 32px;
  line-height: 38px;
  font-stretch: normal;
  letter-spacing: normal;
  text-transform: none;  
}
@mixin font-ml-heading-x-large {
  font-family: GeomanistRegular;
  font-weight: normal;
  font-style: normal;
  font-size: 32px;
  line-height: 38px;
  font-stretch: normal;
  letter-spacing: normal;
  text-transform: none;  
}
@mixin font-ml-heading-large-book {
  font-family: GeomanistBook;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  font-stretch: normal;
  letter-spacing: normal;
  text-transform: none;  
}
@mixin font-ml-heading-large {
  font-family: GeomanistRegular;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  font-stretch: normal;
  letter-spacing: normal;
  text-transform: none;  
}
@mixin font-ml-heading-large-book {
  font-family: GeomanistBook;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  font-stretch: normal;
  letter-spacing: normal;
  text-transform: none;  
}
@mixin font-ml-body-large {
  font-family: GeomanistRegular;
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 32px;
  font-stretch: normal;
  letter-spacing: normal;
  text-transform: none;  
}
@mixin font-ml-body-medium {
  font-family: GeomanistRegular;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  font-stretch: normal;
  letter-spacing: normal;
  text-transform: none;  
}
@mixin font-ml-body-small {
  font-family: GeomanistRegular;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  font-stretch: normal;
  letter-spacing: normal;
  text-transform: none;  
}

@mixin ml-pin-box-shadow { box-shadow: 0 16px 32px 0 rgba($black, 0.07); }
*/
