@font-face {
  font-family: 'Geomanist';
  src: url('fonts/geomanist-regular-webfont.eot');
  src: url('fonts/geomanist-regular-webfont.eot') format('embedded-opentype'),
       url('fonts/geomanist-regular-webfont.woff') format('woff'),
       url('fonts/geomanist-regular-webfont.ttf') format('truetype'),
       url('fonts/geomanist-regular-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist-bold';
  src: url('fonts/geomanist-bold-webfont.eot');
  src: url('fonts/geomanist-bold-webfont.eot') format('embedded-opentype'),
       url('fonts/geomanist-bold-webfont.woff') format('woff'),
       url('fonts/geomanist-bold-webfont.ttf') format('truetype'),
       url('fonts/geomanist-bold-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist-book';
  src: url('fonts/geomanist-book-webfont.eot');
  src: url('fonts/geomanist-book-webfont.eot') format('embedded-opentype'),
       url('fonts/geomanist-book-webfont.woff') format('woff'),
       url('fonts/geomanist-book-webfont.ttf') format('truetype'),
       url('fonts/geomanist-book-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist-medium';
  src: url('fonts/geomanist-medium-webfont.eot');
  src: url('fonts/geomanist-medium-webfont.eot') format('embedded-opentype'),
       url('fonts/geomanist-medium-webfont.woff') format('woff'),
       url('fonts/geomanist-medium-webfont.ttf') format('truetype'),
       url('fonts/geomanist-medium-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}