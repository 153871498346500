#pr-logos-with-popular-makes {

  position: relative;
  
  .container {
    // max-width: 720px;
  }

  h5 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 25px;
  }

  .why-trust-copilot {
    border: 1px solid #000000;
    padding: 15px;
    margin: 25px 0px;

    .why-trust-copilot-header {
      text-align: left;
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 25px;
    }
  }

  .pr-logos-label {
    text-align: center;
    margin-top: 50px;
  }

  .links-section {
    border-top: 1px solid #d0cdcd;
    padding: 16px 24px;
    &:last-child {
      border-bottom: 1px solid #d0cdcd;
    }

    .links-display-toggle {
      font-size: 16px;
      color: #1a1a1a;
      position: relative;
      margin-bottom: 0;
      cursor: pointer;
      min-width: 250px;

      & .caret-down {
        position: absolute;
        right: 20px;
        top: 10px;
        transform: scale(1.8) rotate(180deg);
        filter: brightness(0) saturate(100%) invert(44%) sepia(92%) saturate(2367%) hue-rotate(355deg) brightness(97%) contrast(99%); // ml-orange
      }

    }

    &.collapsed .list-of-links {
      display: none;
    }
    &.collapsed .caret-down {
      transform: scale(1.8) rotate(0);
    }

    .list-of-links {
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 12px 16px;

      & > a {
        width: 200px;
      }

    }

    a {
      text-decoration: none;
      font-family: 'Geomanist';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #1a1a1a;
      cursor: pointer;
      
      .label-line-2 {
        opacity: 66%;
        font-size: 14px;
      }
    }
  }

  .caret-down {
    width: 12px;
    height: 9px;
    display: inline-block;
    background-image: url(/svg/caret-down.svg);
  }
  .caret-up {
    width: 12px;
    height: 9px;
    display: inline-block;    
    background-image: url(/svg/caret-up.svg);
  }

  .makes-container {
    ul a, .makes-display-toggle {
      text-decoration: none;
      font-family: 'Geomanist';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: #000000;
      cursor: pointer;
    }
  
    ul {
      margin-bottom: 0;
    }
  
    #makes-container-secondary {
      display: none;
    }
    
    .makes-display-toggle {
      padding-top: 15px;
      padding-left: 40px;
    
      span {
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        left: 10px;
        width: 13px;
        height: 8px;
      }
    }
    
    #show-more-makes-toggle {
      .caret-down { 
        background-image: url(/svg/caret-down.svg);
      }
    }
    
    #show-less-makes-toggle {
      display: none;
    
      .caret-up { 
        background-image: url(/svg/caret-up.svg);
      }
    }
  }

  @media only screen and (max-width: 648px) {
    .pr-logos-container {
      width: 100%;
    }
  }
}

.nav-item > #pr-logos-with-popular-makes {
  & * {
    font-family: Lato, sans-serif;
    letter-spacing: 0.05em;
    font-size: 16px;
    line-height: 1.8em;
  }
  .links-section {
    border: none;
    padding: 0 14px;
  }
  // p.links-display-toggle {
  //   &:hover:after {
  //     width: 100%;
  //   }
  //   &:hover {
  //     content: "";
  //     position: absolute;
  //     bottom: 0;
  //     left: 14px;
  //     right: 0;
  //     height: 2px;
  //     background-color: #000;
  //     width: 0;
  //     transition: width 0.3s ease, background 0.3s ease;
  //   }
  // }
}

.nav-item {
  cursor: pointer;
}
