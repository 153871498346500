#totop {
  visibility: hidden;
  opacity: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  background-color: $mainColor;
  position: fixed;
  bottom: 0;
  right: 30px;
  border-radius: 50%;
  box-shadow: 0 0 8px 1px rgba(#000, 0.2);
  transition: all 0.2s ease 0s;
  z-index: 1000;

  &.active {
    visibility: visible;
    bottom: 30px;
    opacity: 1;

    &:hover {
      bottom: 35px;
    }
  }
}
