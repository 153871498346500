body {
  font-size: 1em;
  line-height: 1.8em;
  font-weight: 300;
  // font-family: "Open Sans", sans-serif;
  // font-family: 'Montserrat', sans-serif;
  font-family: "Lato", sans-serif;
  color: $fontColor;
}

* {
  box-sizing: border-box;
}

article {
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Geomanist";
  font-weight: 500;
  color: $darkGreyColor;
  line-height: 1.3em;
  margin-top: 1em;
  margin-bottom: 0.8em;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    color: $darkGreyColor;

    &:hover {
      color: $mainColor;
      text-decoration: none;
    }
  }
}

p {
  font-family: "Geomanist";
  margin-bottom: 10px;
}

img {
  max-width: 100%;
}

p {
  &:last-child {
    margin-bottom: 0;
  }
}

strong {
  font-weight: 600;
}

a {
  color: $mainColor;
  text-decoration: underline;
  transition: all 0.2s ease 0s;

  &:hover {
    color: $darkGreyColor;
    transition: all 0.05s ease 0s;
  }
}

ul {
  &:last-child {
    margin-bottom: 0;
  }
}

blockquote {
  font-style: italic;
  font-size: 20px;

  footer {
    font-style: normal;
    font-size: 16px;

    &:before {
      content: "-";
      margin-right: 10px;
    }
  }
}

aside {
  div.with_background {
    padding: 15px;
    border-radius: 4px;
  }
}
