.feature_list {
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 15px;
  padding-left: 20px;

  > li {
    position: relative;
    box-sizing: border-box;

    + li {
      margin-top: 5px;
    }

    &:before {
      content: "\f054";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 0.8em;
      position: absolute;
      top: 1px;
      left: -20px;
      color: $darkGreyColor;
    }

    > * {
      display: inline-block;
    }

    strong,
    .attr_name {
      color: $darkGreyColor;
    }

    .attr_name {
      min-width: 190px;
      padding-right: 10px;
    }
  }

  @media (min-width: 1200px) {
    margin-left: 20px;
  }

  @media (min-width: 768px) {
    &.two_cols {
      display: flex;
      flex-wrap: wrap;
      margin-left: -5px;
      margin-right: -5px;
      padding-left: 0;

      > li {
        width: calc(50% - 10px);
        margin: 2px 5px 2px;
        padding-left: 25px;

        &:before {
          left: 0;
        }

        .attr_name {
          min-width: 150px;
        }
      }
    }
  }
}
