// input[type="date"],
// input[type="datetime-local"],
// input[type="email"],
// input[type="number"],
// input[type="password"],
// input[type="search"],
// input[type="tel"],
// input[type="text"],
// input[type="time"],
// input[type="url"],
// textarea,
// select {
//   min-height: 40px;
//   border: 1px solid #ccc;
//   border-radius: 20px;
//   padding: 5px 25px;
//   display: inline-block;
//   background-color: transparent;

//   &:focus {
//     outline: none;
//     border-color: #66afe9;
//     box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
//       0 0 8px rgba(102, 175, 233, 0.6);
//   }

//   &[readonly],
//   &[disabled] {
//     color: var(--test-color);
//     background-color: rgba(#ccc, 0.3);
//     pointer-events: none;
//   }
// }

// select.form-control {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   padding: 5px 10px;
// }

.form-control {
  padding: 5px 10px;
}

.form-check-input {
  margin-top: 0.38rem;
}

.valet_input_group {
  padding: 5px 10px;
  line-height: 1.5em;
  max-height: 150px;
  overflow-y: auto;
  border-radius: 4px;

  .form-check-input {
    margin-top: 0.24rem;
  }
}

/* Range selector override */
.range_selector {
  margin-top: 15px;
  // margin-bottom: 15px;

  .slider-container {
    max-width: 100%;

    .back-bar {
      background-color: rgba(#000, 0.1);
      border-radius: 3px;
      height: 4px;

      .pointer {
        width: 14px;
        height: 14px;
        top: -5px;
        border-radius: 50%;

        &.low {
          background-color: $mainColor2;
        }

        &.high {
          background-color: $mainColor;
        }
      }

      .selected-bar {
        background: linear-gradient(to right, $mainColor2, $mainColor);
      }
    }
  }

  .range_value {
    margin-top: 5px;
    text-align: center;
  }
}
/** style overwrites */
.range_selector{margin-top:0}
.range_selector.single-value {
  margin-top:35px
}
.range_selector .slider-container .back-bar {
  background:#aaa;
}
.range_selector .slider-container .back-bar .selected-bar{
  background: #446EBA;
}
.range_selector.single-value div.pointer.low {
  display:none;
}
.range_selector .slider-container .back-bar div.pointer{
  background-color: #fff;
  border-radius: 100%;
  cursor: move;
  height: 20px;
  left: 0;
  top: -9px;
  width: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
}
.range_selector .slider-container .back-bar div.pointer.low,
.range_selector .slider-container .back-bar div.pointer.high {
  background-color:#fff
}
.range_selector .range_value.single {
  color:#000;
  font-size: 14px;
  font-family: "Geomanist";
  font-weight: 400;
  margin-top:15px; 
  text-align:right;
}
.range_selector .range_value.multi {
  color:rgba(26,26,26, 0.5);
  font-size: 14px;
  font-family: "Geomanist";
  font-weight: 400;  
  height:26px;
  margin:15px -15px 0;
  position:relative;
}
.range_selector .range_value.multi em {
  position:absolute;
  font-style:normal;
  top:0
}
.range_selector .range_value.multi em.left {
  left:0;
}
.range_selector .range_value.multi em.right {
  right:0
}


