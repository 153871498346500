.pagination,
.valet_pagination {
  font-family: 'Geomanist';
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  line-height: 1em;
}
.post + .pagination,
.post + .valet_pagination {
  margin-top: 30px;
}
.pagination li,
.valet_pagination li {
  display: flex;
  border: none;
  margin-right: 8px;

  @media (max-width: 767px) {
    font-size: 14px;
    margin-right: 4px;
  }
}
.pagination li > a, 
.pagination li > span,
.valet_pagination li > a,
.valet_pagination li > span {
  padding: 8px 10px;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  text-align:center;
}
.valet_pagination em {font-style:normal}
.valet_pagination li.first a,
.valet_pagination li.prev a,
.valet_pagination li.next a,
.valet_pagination li.first span,
.valet_pagination li.prev span,
.valet_pagination li.next span{ 
  border:0; 
  padding:8px 0;
}
.valet_pagination li.total span {
  border:0;
  padding-left:0
}
.valet_pagination li.total.dots {margin-right:0; padding-left:10px}
.pagination li:first-child,
.valet_pagination li.first,
.valet_pagination li.prev {
  margin-right:24px;

  @media (max-width: 767px) {
    margin-right: 0px;
  }
}
.valet_pagination li.first i,
.valet_pagination li.prev i {
  margin-right:10px
}
.pagination li:last-child,
.valet_pagination li.next {
  margin-right:0;
  margin-left:16px;

  @media (max-width: 767px) {
    margin-left: 0px;
  }
}
.valet_pagination li.next i {
  margin-left:10px
}
.pagination a,
.valet_pagination a {
  text-decoration: none;
  color: $darkGreyColor;
}
.pagination a:hover, .pagination a.active,
.valet_pagination a:hover,
.valet_pagination a.active {
  color: var(--header-orange);;
  background-color: transparent;
  border-color:var(--header-orange);;
}
.pagination span,
.valet_pagination span {
  color: $dsFontColor;
}
.pagination span.active,
.valet_pagination span.active {
  background:#fff;
  color: var(--header-orange);
  border-color:var(--header-orange);;
}

.valet_pagination li.first span,
.valet_pagination li.prev span  {color:#2641A2}

.pagination .page-item.active .page-link {
  color: var(--header-orange);;
  background-color: transparent;
}
.pagination a:hover span {
  color: var(--header-orange);
}

.valet_pagination li.first a,
.valet_pagination li.prev a,
.valet_pagination li.next a {
  color:#2641A2
}
.valet_pagination li.first span,
.valet_pagination li.first a {padding-top:5px;}
.valet_pagination li.first i.line {
background:#2641A2;
  border-radius:1px;
  display:inline-block;
  height:16px;
  margin-right:1px;
  position:relative;
  top:2px;
  width:2px;
}

.pagination_meta {
  font-size: 14px;
  line-height: 1em;
  text-align: center;
}

.bottom_pagination {
  margin-top: 15px;
  display: none;
  justify-content: flex-end;
}
