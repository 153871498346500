.press-content {
	.card {
		box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05) !important;
		border: none !important;

		.btn-apply {
			border-radius: 50px;
			background-color: white;
			color: #f6610c;
			border: 1px solid #f6610c;
			font-family: Geomanist-book;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			text-transform: none;
			max-width: 450px;
			margin: 0px auto;
			padding: 12px 0px;

			&:hover {
				background: linear-gradient(37deg, #b43e79 1%, #f7463a 100%) !important;
				color: #fff;
				border-color: #f7463a;
			}

			&:focus {
				background: linear-gradient(37deg, #b43e79 1%, #f7463a 100%) !important;
				color: #fff;
				border-color: #f7463a;
				outline: none !important;
			}

			&:focus-visible {
				outline: none !important;
			}
		}
	}

	@media (min-width: 1200px) {
		.container {
			max-width: 960px;
		}
	}
}

.press-page-content {
	.card-date {
		font-family: 'Geomanist';
		font-size: 16px;
	}

	.card-text {
		font-family: 'Geomanist';
		font-size: 16px;
		text-transform: capitalize;
	}

	.read-more {
		font-size: 16px;
		text-transform: capitalize;
	}

}
