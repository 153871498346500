.pr-logos {
    background-color: #FFFFFF;
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
    height: fit-content;
    align-items: center;
    justify-content: center;
    &-label {
        color: #000000;
        margin-bottom: 25px;
        font-size: 1.25em;
    }
    &-container {
        display: flex;
        height: inherit;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        .pr-logo {
            height: 85px;
            width: 85px;
            margin: 10px;
            box-shadow: 0px 0px 13px #959595;
            border-radius: 50%;
        }
    }
}

@media only screen and (max-width: 648px) {
    .pr-logos {
        &-container {
            width: 90%;
            height: 65%;
            .pr-logo {
                height: 60px;
                width: 60px;
            }
        }
    }
}