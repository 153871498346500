#canvas {
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#content {
  flex-grow: 1;
  position: relative;

  &.with-overlay {
    > * {
      display: none;

      &#content-overlay {
        display: block;
      }
    }
  }
}
.parallax {
  background-size: cover;
  background-position: center;
}

.cover_image {
  background-size: cover;
  background-position: center;
}

.standard_spacing {
  padding-top: 75px;
  padding-bottom: 75px;

  &.reduced-top-padding{
    padding-top: 37px;
  }
}

.small_spacing {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 992px) {
  .standard_spacing {
    padding-top: 100px;
    padding-bottom: 100px;

    &.reduced-top-padding{
      padding-top: 50px;
    }
  }
  .small_spacing {

  }
}

.section_header {
  @media (min-width: 768px) {
    font-size: 40px;
    margin-bottom: 0.6em;
  }

  @media (min-width: 1200px) {
    font-size: 48px;
  }

  .text-center & {
    + p {
      font-size: 18px;
      max-width: 860px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.row.columns_padding_30 {
  @media (min-width: 1200px) {
    margin-right: -30px;
    margin-left: -30px;

    > [class*="col-"] {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
}

.color_overlay {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  & > * {
    position: relative;
    z-index: 1;
  }

  &.ds {
    &:before {
      background-color: rgba($darkColoredColor, 0.8);
    }
  }
}

@media (max-width: 991px) {
  aside:not(:first-child) {
    margin-top: 60px;
  }

  aside:first-child {
    margin-bottom: 60px;
  }
}

aside {
  .sticky-top {
    top: 30px;
  }
}
