#page_not_found {
  .not_found {
    font-size: 120px;
    line-height: 0.8em;
    font-weight: 900;
    font-family: "Oswald", sans-serif;
    color: $mainColor;

    + h1 {
      margin-top: 0.8em;
    }
  }
}
