.site_footer {
  padding-top: 50px;
  // padding-bottom: 50px;
  font-size: 14px;
  color: #fff;
  background-color: $darkGreyColor;
  
  p {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
  }

  div[class*=col-] {
    margin-bottom: 50px;
  }

  .widget {
    padding-bottom: 25px;
    border-bottom: 1px solid $dsBorderColor;
    height: 100%;
  }

  @media (min-width: 1200px) {
    padding-top: 90px;
    padding-bottom: 40px;
  }
}

#footer {
  background-color: #131313;
  color:#595959;
  display:flex;
  font-family: 'Geomanist';
  min-height:300px;
  padding:25px;
  margin-top:60px;
}
#footer div.section {
  width:25%;
}

#footer div.section h5,
#tablet-footer h5 {
  color:#fff;
  font-family:'Geomanist';
  font-size:20px;
  font-weight:300;
  margin-bottom:40px;
}

#footer div.section p.footer-row {}

#footer div.section p.footer-row a,
#tablet-footer p.footer-row a{
  color:#595959;
  display:inline-block;
  text-decoration:none;
}

#footer div.section p.footer-row a.icon {
  font-size:35px;
  padding-right:40px;
}

#tablet-footer p.footer-row a.icon {
  font-size:30px;
  padding-right:40px;
}

#footer div.section p.footer-row a:hover,
#tablet-footer p.footer-row a:hover {
  color:#b1b1b1;
}

#footer a.logo {
  display:inline-block;
}

#footer p.all-rights {
  color:#595959;
  margin:40px 0 0 25px;
  text-align:center;
  width:52%;
}

#mobile-footer,
#tablet-footer {  
  color:#595959;
  background-color: $darkGreyColor;
  display:none;
  padding:50px 0;
}
#tablet-footer {padding-bottom:0}

#footer,
#mobile-footer,
#tablet-footer{
  margin-top:60px;
}

#mobile-footer div.footer-list {
  background:#262626;
  border-radius:5px;
  margin:0 10% 15px;
  width:80%;
}

#mobile-footer div.footer-list a.footer-list-toggle {
  color:#fff;
  display:block;
  font-family: "Geomanist";
  font-size:14px;
  font-weight:500;
  padding:15px;
  position:relative;
  text-decoration:none;
}
#mobile-footer div.footer-list a.footer-list-toggle i {
  position:absolute;
  top:20px;
  right:15px;
}
#mobile-footer div.footer-list a.footer-list-toggle i.fa-chevron-up,
#mobile-footer div.footer-list a.footer-list-toggle.open i.fa-chevron-down {
  display:none;
}
#mobile-footer div.footer-list a.footer-list-toggle.open i.fa-chevron-up {
  display:block;
}
#mobile-footer div.footer-list div.footer-list-content {
  display:none;
  padding:0 15px;
}

#mobile-footer div.footer-list div.footer-list-content a,
#mobile-footer div.footer-list div.footer-list-content p {
  color:#fff;
  display:block;
  font-family: "Geomanist";
  font-size:14px;
  font-weight:500;
  line-height:20px;
  padding:10px 0;
  text-decoration:none;  
}
#mobile-footer div.footer-list div.footer-list-content a.inline {
  display:inline-block;
  padding:0;
}

#mobile-footer div.footer-list div.footer-list-content.open {
  display:block
}

#mobile-footer p.social-links {
  padding-top:50px;
  text-align:center;
}
#mobile-footer p.social-links a {
  color:#fff;
  display:inline-block;
  font-size:20px;
  padding:0 15px;
  text-decoration:none;
}
#mobile-footer p.all-rights {
  color:#595959;
  font-family: "Geomanist";
  font-size:14px;
  margin:15px 0 25px;
  text-align:center; 
}
#mobile-footer a.logo {
  display:block;
  margin:0 auto;
  width:100px;
}

#tablet-footer table {
  margin:auto;
  width:70%;
}

#tablet-footer table td {
  vertical-align:top;
  padding-bottom:40px;
}

#tablet-footer table tr td:nth-of-type(1) {
  width:200px;
}

