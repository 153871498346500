/**
* css for screens 480px and smaller
* PHONES
*/
@media screen and (max-width: 480px) {

  #header div.navigation-options,
  #footer,
  div.valet_srp_controls div.valet_srp_pagination,
  .valet-package-content span.show-more-packages {
    display:none;
  }
  #mobile-menu-toggle {
    display:inline-block;
  }
  div.mobile-navigation-options.show,
  #srp_layout,
  #mobile-footer {
    display: block;
  }
  #srp_filter_wrapper {
    background:#fff;
    border:1px solid #e6e6e6;
    border-top:0;
    border-bottom:0;
    margin:0 auto;
    width:100%;
  }
  #srp_filter_wrapper.show {
    display:block;
  }
  #srp_results_wrapper {
    background:#f8f8f8;
    padding-bottom:15px;
    width:100%;
  }
  #srp_results_wrapper .valet_srp_header {
    border-left:0;
    border-top:1px solid #e6e6e6;
  }
  div.range_selector{
    margin-left:auto;
    margin-right:auto;
    width: 270px;
  }
  #price-histogram {
    margin-left:auto;
    margin-right:auto;
  }
  div.star-rating {
    display:block;
    transform: translate(0, 0);
    margin-left:auto;
    margin-right:auto;
  }
  h2.srp_display {
    font-family: "Roboto";
    font-size: 24px;
    font-weight: 700;
    padding:0;
  }
  div.valet_item_media {
    float: none;
    position: relative;
    width: 100%;
    max-width: 100%;
  }
  .valet_item_image {
    max-height:150px;
    padding-bottom:150px;
  }
  .valet_item_content {
    display:block;
    float:none;
    min-height:inherit;
    padding: 0 25px;
    position:relative;
    width:100%;
  }
  .valet_item_details {
    border:0;
    padding-left:0;
    width:100%;
  }
  .valet_item_advice {
    overflow:auto;
    padding-left:0;
    padding-bottom:20px;
    width:95%
  }
  .valet-package-content {
    padding-bottom:0;
  }
  .valet-packages-wrapper {
    display:block;
  }
  .valet-packages-wrapper .valet-package {
    padding-bottom:20px;
    width:100%;
  }
  .valet-packages-wrapper .valet-package span.package-item.hidden {
    display:list-item;
  }
  .bottom_pagination {
    display:block;
    text-align:center;
    padding:0 25px 15px;
  }

  .bottom_pagination .valet_pagination {
    display:flex;
    height: 53px;
    text-align: center;
    overflow: auto;
    padding-top: 13px;
  }

  .bottom_pagination .valet_pagination li {
    display:inline-block;
  }
  .bottom_pagination .valet_pagination li em {display:none}
  .bottom_pagination .valet_pagination li.first {white-space:nowrap}

  #results-list-cta-box h5 {
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 30px;
  }

  .internal_link {
    width:100% !important;
  }

  #srp-category-types div.make-category-wrapper.show {
    width:inherit;
  }

  #srp-make-vlp-internal-links div a {
    width:calc(100%);
  }
  #srp-make-something-else div a {
    width:calc(100%/3);
  }

  #make-dropdown {
    border-top: 1px solid #e6e6e6;
    border-left:0;
    left: 0;
    right:auto;
    top: 275px;
    width: 100%;
  }

  #make-dropdown a {
    width:100%;
  }

  #srp-model-container {
    width:calc(100% - 40px)
  }

  #srp-model-container div.model-block div.model-block-section.mobile-special {
    border:0;
  }
  div.model-details-row div.left-block {
   float:none;
  }
  div.model-details-row div.range {
    float:none;
    display:inline-block;
    margin:15px 15px 0 0;
  }
  div.model-details-row div.range span.values, 
  div.model-details-row div.range span.title {
    text-align:left
  }
  #srp-model-container div.model-block div.model-columns div.model-col.two,
  #srp-model-container div.model-block div.model-columns div.model-col {
    width:100%;
  }
  #srp-model-container div.model-block div.model-columns div.model-col.mobile-special {
    border-top:1px solid #eee;
    padding-top:5px;
  }

  .rate-object i {
    font-size:18px;
  }
  .rating-name {
    line-height:18px;
  }
  .rating-name.big {
    line-height:26px;
  }
  #srp-model-container div.model-block div.company span.name.fixable {line-height:22px;}
  #srp-model-container div.model-block div.company span.rate-object.large.right {
    float:none;
    position:absolute;
    left: 85px;
    top: 12px;
  }
  div.model-details-row div.range.seats svg,
  div.model-details-row div.range.cases svg {height:25px;}
  div.model-details-row div.range.cases svg {margin:0 -5px 0 0}
  div.model-details-row div.range span.green svg,
  div.model-details-row div.range span.gray svg {height:25px; width:26px;}  
  div.model-details-row span.flag {display:none;}
  div.model-details-row span.flag.mobile {display:block; float:right}
  #srp-model-container div.model-block div.company span.rate-object.large.right i {font-size:18px;}

  #srp-model-container div.rating-legend div.legend-value {
    width:50%;
    margin-bottom:10px;
  }
  div.srp-model-price-range-chart div.chart {
    margin:85px auto 0;
    width:100%;
  }
  div.srp-model-price-range-chart div.price-range{
    width:100%;
  }

  p.model-overview-cta-wrapper a {
    font-size:20px;
    height:48px;
    line-height:48px; 
    margin:15px auto 50px;
    width:350px;
  }
  p.model-overview-cta-wrapper {
    position:sticky;
    bottom:-40px;
    background:#fff;
    padding:10px 0;
  }

  #totop.active {bottom:50px; }

  #srp-model-container h1 {
    font-size:22px;
    line-height:28px;
  }
  #srp-model-container p.copilot-class {
    font-size:16px;
    line-height:22px;
  }
  #srp-model-container p.model-description {
    font-size:15px;
    line-height:20px;
  }

  .valet_item_content span.valet-package-toggle,
  a.close-packages-section {display:none}

}
/**
* css for screens between 480px and 1000px
* Tablets
*/
@media screen and (max-width: 1000px) and (min-width: 480.02px) {

  #header div.navigation-options,
  div.valet_srp_controls div.valet_srp_pagination,
  .valet-package-content span.show-more-packages,
  #footer {
    display:none;
  }
  #tablet-footer {
    display:block;
  }
  #mobile-menu-toggle {
    display:inline-block;
  }
  div.mobile-navigation-options.show {
    border-bottom:1px solid #e6e6e6;
    display: block;
  }

  #srp_layout {
    position:relative;
  }

  #tablet-filter-toggle,
  #tablet-filter-close {
    background:#fff;
    color: $fontColor;
    display:block;
    font-family: "Roboto";
    font-size: 14px;  
    font-weight:300;  
    text-align:center;
    text-decoration:none;
    width:64px;
  }
  #tablet-filter-close {
    border-left:1px solid #e6e6e6;
    box-shadow:0 0 10px rgba(0,0,0,0.4);
    height:100%;
    left:-75px;
    position:fixed;
    -webkit-transition: left 0.4s ease-in-out;
    transition: left 0.4s ease-in-out;
    top:0;
    z-index:3;
  }
  #tablet-filter-close.show {
    left:320px
  }
  #tablet-filter-toggle i,
  #tablet-filter-close i {
    margin-top:15px;
    font-size:24px;
  }
  #tablet-filter-toggle span,
  #tablet-filter-close span {
    display:block;
    line-height:20px;
    padding:10px;
  }

  #srp_filter_wrapper {
    background:#fff;
    border:0;
    height:100%;
    overflow:auto;
    position:fixed;
    left:-325px;
    top:0;
    -webkit-transition: left 0.4s ease-in-out;
    transition: left 0.4s ease-in-out;
    width:320px;
    z-index:4;
  }
  #srp_filter_wrapper.open {
    left:0px;
  }
  #srp_results_wrapper {
    background:#f8f8f8;
    padding-bottom:15px;
    width:100%;
  }
  .valet_item_media {
    height: 203px;
  }
  .valet_item_image {
    height: 100%;
  }
  .valet_item_content {
    display:block;
  }
  .valet_item_details {
    height:169px;
    width:100%;
  }
  .valet_item_advice {
    border-top:1px solid #e6e6e6;
    margin-left:-250px;
    overflow:hidden;
    padding:0 0 25px 35px;
    width:calc(100% + 250px);
  }

  .valet_item_content span.first-take-price {
    float:left;
    width:calc(50% - 25px);
  }
  .valet-package-content {
    padding-bottom:0;
  }
  .valet-packages-wrapper {
    display:block;
  }
  .valet-packages-wrapper .valet-package {
    padding-bottom:20px;
    width:100%;
  }
  .valet-packages-wrapper .valet-package span.package-item.hidden {
    display:list-item;
  }
  .bottom_pagination {
    display:block;
    text-align:center;
    padding:0 25px 15px;
  }

  .bottom_pagination .valet_pagination {
    display:block;
    text-align:center;
  }

  .bottom_pagination .valet_pagination li {
    display:inline-block;
  }  
  h2.srp_display {
    font-family: "Roboto";
    font-size: 36px;
    font-weight: 700;
    padding:0;
  }

  #tablet-filter-bg {
    background:rgba(0,0,0,0.3);
    height:100%;
    left:0;
    position:fixed;
    top:0;
    width:100%;
    z-index:2;
  }

  #srp-category-types div.make-category-wrapper.show {
    width:inherit;
  }

  #srp-make-something-else div a {
    width:calc(100%/5);
  }

  #make-dropdown {
    border-top: 1px solid #e6e6e6;
    border-left:0;
    left: 0;
    right:auto;
    top: 275px;
    width: 100%;
  }

  #make-dropdown a {
    width:calc(100%/5);
  }

  #srp-model-container {
    width:90%;
  }

  #srp-model-container div.model-block div.model-columns div.model-col.two .rate-object i {
    font-size:18px;
  }

  #srp-model-container div.model-block div.model-columns div.model-col.two .rating-name {
    line-height:18px;
  }

}

@media screen and (min-width: 1000.02px) {
  .valet_item_media {
    height: 203px;
  }
  .valet_item_image {
    height: 100%;
  }
}
