.rate-object {
  display:inline-block;
  vertical-aling:top;
}

.rate-object.right {
  float:right;
}

.rate-object i {
  color:#E1E6ED;
  display:inline-block;
  font-size:25px;
  vertical-align:top;
}

.rate-object.large i {
  font-size:30px;
  line-height:64px;
}

.rate-object.smaller i {
  font-size:18px;
}
 
.rating-name {
  color:$fontColor;
  display:inline-block;
  font-family:'Geomanist';
  font-size:14px;
  font-weight:100;
  line-height:25px;
  white-space:nowrap;	
  margin-left:15px;
  vertical-align:top;
}

.rate-object i.fa-star-half-alt,
.rate-object i.fa-adjust,
.rate-object.rating-0_5 i.one,
.rate-object.rating-1_5 i.two,
.rate-object.rating-2_5 i.three,
.rate-object.rating-3_5 i.four,
.rate-object.rating-4_5 i.five {display:none}

.rate-object.rating-1 i.one,
.rate-object.rating-1_5 i.one,
.rate-object.rating-2 i.one,
.rate-object.rating-2 i.two,
.rate-object.rating-2_5 i.one,
.rate-object.rating-2_5 i.two,
.rate-object.rating-3 i.one,
.rate-object.rating-3 i.two,
.rate-object.rating-3 i.three,
.rate-object.rating-3_5 i.one,
.rate-object.rating-3_5 i.two,
.rate-object.rating-3_5 i.three,
.rate-object.rating-4 i.one,
.rate-object.rating-4 i.two,
.rate-object.rating-4 i.three,
.rate-object.rating-4 i.four,
.rate-object.rating-4_5 i.one,
.rate-object.rating-4_5 i.two,
.rate-object.rating-4_5 i.three,
.rate-object.rating-4_5 i.four,
.rate-object.rating-5 i.one,
.rate-object.rating-5 i.two,
.rate-object.rating-5 i.three,
.rate-object.rating-5 i.four,
.rate-object.rating-5 i.five {color:#FFDB26}

.rate-object.rating-0_5 i.fa-star-half-alt.one,
.rate-object.rating-1_5 i.fa-star-half-alt.two,
.rate-object.rating-2_5 i.fa-star-half-alt.three,
.rate-object.rating-3_5 i.fa-star-half-alt.four,
.rate-object.rating-4_5 i.fa-star-half-alt.five,
.rate-object.rating-0_5 i.fa-adjust.one,
.rate-object.rating-1_5 i.fa-adjust.two,
.rate-object.rating-2_5 i.fa-adjust.three,
.rate-object.rating-3_5 i.fa-adjust.four,
.rate-object.rating-4_5 i.fa-adjust.five {display:inline-block; color:#FFDB26}

.rate-object.rating-0 i.one,
.rate-object.rating-0 i.two,
.rate-object.rating-0 i.three,
.rate-object.rating-0 i.four,
.rate-object.rating-0 i.five,
.rate-object.rating-1 i.two,
.rate-object.rating-1 i.three,
.rate-object.rating-1 i.four,
.rate-object.rating-1 i.five,
.rate-object.rating-1_5 i.three,
.rate-object.rating-1_5 i.four,
.rate-object.rating-1_5 i.five,
.rate-object.rating-2 i.three,
.rate-object.rating-2 i.four,
.rate-object.rating-2 i.five,
.rate-object.rating-2_5 i.four,
.rate-object.rating-2_5 i.five,
.rate-object.rating-3 i.four,
.rate-object.rating-3 i.five,
.rate-object.rating-3_5 i.five,
.rate-object.rating-4 i.five {color:#E1E6ED}