.board_header #header {
  border-bottom: none;
}

.board-container {
  &.bio {
    padding: 10px 33px 33px;

    .margin_top_30 {
      position: relative;
    }

    .member_profile {
      margin-bottom: 30px;
    }

    .bio-linkedin {
      position: absolute;
      right: 0;
      bottom: 30px;
      font-size: 14px;
    }

    .bio-container {
      font-size: 16px;
      line-height: 30px;
      color: rgba(26, 26, 26, 0.6);
      .para-header {
        font-weight: 600;
        padding-top: 10px;
      }
    }
  }

  .row {
    text-align: center;
    flex-wrap: unset;
    margin: 0 auto;
    width: 100%;
    display: inline-block;
  }

  .member_profile {
    margin-right: 20px;
    margin-bottom: 40px;
    display: inline-block;
    text-align: left;

    &.right {
        margin-left: 20px;
        margin-right: 0;
    }

    .board-name {
      margin-top: 29px;
      font-size: 23px;
      color: #000000;
    }

    .board-title {
      margin-top: 6px;
      font-size: 16.5px;
      color: #1a1a1a;
    }

    .board-image {
      width: 143px;
      height: 143px;
      border-radius: 143px;

      &.pat {
        background-image: url(/img/pat.png);
        background-repeat: no-repeat;
        background-size: cover;
      }
      &.rob {
        background-image: url(/img/rob.png);
        background-repeat: no-repeat;
        background-size: cover;
      }
      &.tom {
        background-image: url(/img/tom.png);
        background-repeat: no-repeat;
        background-size: cover;
      }
      &.brett {
        background-image: url(/img/brett.png);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  .header-link {
    color: #1a1a1a;

    a {
      text-decoration: none;
      color: #f6610c;
      font-family: 'Geomanist';

      &:hover {
        color: #1a1a1a;
      }
    }
  }
}