#how_it_works {
  .steps_image {
    max-width: 300px;
    width: 100%;

    @media (min-width: 992px) {
      max-width: 100%;
    }
  }
}

#steps {
  // background-image: url("/images/steps_bg.jpg");

  .container {
    margin-top: -25px;
  }

  .col-md-4 {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
      margin-top: auto;
    }
  }
}

#testimonials {
  color: #fff;

  blockquote {
    margin-bottom: 0;

    footer {
      color: rgba(#fff, 0.7);
    }
  }

  @media (min-width: 992px) {
    blockquote {
      font-size: 30px;
      line-height: 1.3em;

      footer {
        font-size: 20px;
      }
    }
  }

  @media (min-width: 1200px) {
    blockquote {
      font-size: 36px;

      footer {
        font-size: 24px;
      }
    }
  }
  // blockquote {
  //   font-size: 40px;
  //   line-height: 1.3em;
  //   margin-bottom: 0;
  // }
}

.pain_points{
  background-color: $ml_mainOrange;
  height: 661px;

  .overlaying-numbers{
    font-family: Geomanist;
    font-size: 200px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f7803c;
    position: absolute;
    top:-20px;
    left: -10px;
    z-index: -1;
  }

  .pain-point-section_title{
    color: $ml_mainWhite;
    font-family: Geomanist;
    font-size: 32px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;

    position: absolute;
    top: 50px;
    left: 50px;
    width: 70px;
  }

  .ml-pain-point{
    background: $ml_mainWhite;
    border-radius: 8px;
    width: 311px;
    height: 391px;
    margin-top: 172px;
    padding-top: 0px;

    &.no-color{
      background-color: unset;
      width: 80%;
      height: 391px;
    }

    .row{

      .ml-pain-point-padding{
        padding-right: 32px;
        padding-left: 32px;
        margin-left: 0px !important;
        max-width: 100% !important;
        flex: 0 0 100%;

      }
    }


    .pain-point-icon{
      width: 80px;
      height: 80px;
      margin-top: 48px;
      margin-bottom: 24px;
      border-radius: 50%;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center, center;

      &.better-buys{
        background-image: url("/img/better-deals-icon.png");
      }
      &.always-free{
        background-image: url("/img/always-free-icon.png");
      }
      &.real-experts{
        background-image: url("/img/real-experts-icon.png");
      }
      &.skip-legwork{
        background-image: url("/img/skip-legwork-icon.png");
      }

      &.mathew-hamilton{
        //background-image: url("/img/mathew-hamilton-icon.png");
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
      }

      &.andrea_w{
        background-image: url("/img/cc_testimonial_andrea_w.webp"), url("/img/cc_testimonial_andrea_w.jp2");
        background-size: cover;
      }
      &.casey_m{
        background-image: url("/img/cc_testimonial_casey_m.webp"), url("/img/cc_testimonial_casey_m.jp2");
        background-size: cover;
      }
      &.emily_w{
        background-image: url("/img/cc_testimonial_emily_w.webp"), url("/img/cc_testimonial_emily_w.jp2");
        background-size: cover;
      }
      &.jessica_e{
        background-image: url("/img/cc_testimonial_jessica_e.webp"), url("/img/cc_testimonial_jessica_e.jp2");
        background-size: cover;
      }
      &.jessica_w{
        background-image: url("/img/cc_testimonial_jessica_w.webp"), url("/img/cc_testimonial_jessica_w.jp2");
        background-size: cover;
      }
      &.salvador_e{
        background-image: url("/img/cc_testimonial_salvador_e.webp"), url("/img/cc_testimonial_salvador_e.jp2");
        background-size: cover;
      }
    }

    .pain-point-title{
      font-family: Geomanist;
      font-size: 22px;
      padding-bottom: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    .pain-point-subheader{
      font-family: Geomanist;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.88;
      letter-spacing: normal;
      color: rgba(26, 26, 26, 0.6);
    }
  }

  .flex-control-nav{
    bottom: -30px !important;
  }

  .flex-direction-nav{
    .flex-nav-next{
      .flex-next{
        right: 61px;
        bottom: -50px;
        top: unset;
      }
    }
    .flex-nav-prev{
      .flex-prev{
        left: 61px;
        bottom: -50px;
        top: unset;
      }
    }
  }
}

.ml_testimonials{
  .pain-point-section_title{
    width:200px;
  }

  blockquote{
    position: relative;

    .quotation-icon{
      width: 22.5px;
      height: 17.7px;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center, center;

      background-image: url("/img/quotation-icon.png");

      position: absolute;
      top: 0px;
      left: -33.2px;
    }

    p{
      color: $ml_mainWhite;
    }

    footer{
      color: $ml_mainWhite;
    }
  }
  .flex-control-nav{
    bottom: -30px !important;
  }
}

#how-copilot-works{

  .ml-how-copilot-works-padding{
    padding-left: 32px;
    padding-right: 32px;
  }

  .text-center{


    .section_header{
      text-align: left;
      font-family: Geomanist;
      font-size: 32px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    p{
      opacity: 0.5;
      font-family: Geomanist;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.78;
      letter-spacing: normal;
      color: #000000;
      text-align: left;
      margin-left: 0px;
    }
  }

  .steps{
    padding-top: 50px;
    padding-bottom: 50px;

    .step{
      padding-top: 20px;
      padding-bottom: 20px;

      .icons{
        display: inline-block;
        vertical-align: top;

        .icon{
          width: 24px;
          height: 24px;

          background-repeat: no-repeat;
          background-size: contain;
          background-position: center, center;

          vertical-align: top;
          margin-right: 32px;

          &.chat{
            background-image: url("/img/chat-icon.png");
          }
          &.save{
            background-image: url("/img/save-icon.png");
          }
          &.document{
            background-image: url("/img/document-icon.png");
          }

          &.curated_ranked_icon {
            background-image: url("/img/curated_ranked_icon.png");
          }
          &.independent_icon{
            background-image: url("/img/independent_icon.png");
          }
          &.expert_insights_icon{
            background-image: url("/img/expert_insights_icon.png");
          }
          &.comprehensive_search_icon{
            background-image: url("/img/comprehensive_search_icon.png");
          }
        }
      }

      .copy{
        display: inline-block;
        position: relative;

        width: 70%;

        .title{
          font-family: Geomanist;
          font-size: 24px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          text-align: left;
        }

        .sub-copy{
          font-family: Geomanist;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.88;
          letter-spacing: normal;
          color: rgba(26, 26, 26, 0.6);
          text-align: left;
        }

        .border-line{
          position: absolute;
          width: 100%;
          height: 60%;
          top: 50px;
          left: -50px;
          border-left: 1px solid;
          opacity: 0.1;
        }
      }
    }
  }

}