.social.fab {
  text-decoration: none;

  + .social.fab {
    margin-left: 6px;
  }

  &.color-bg {
    line-height: 40px;
    width: 40px;
    text-align: center;
    color: #fff;
    border-radius: 50%;

    &:hover {
      opacity: 0.7;
    }

    &.fa-facebook-f {
      background-color: #3b5998;
    }
    &.fa-twitter {
      background-color: #1da1f2;
    }
    &.fa-youtube {
      background-color: #ff0000;
    }
    &.fa-instagram {
      background-color: #c32aa3;
    }
  }
}
