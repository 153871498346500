#srp-model-container {
  margin:25px auto;
  width:70%;
}
#srp-model-container h1 {
  font-family: "Geomanist-book"; 
  font-size:32px;
  font-weight:300;
  color:#263040;
  line-height:40px;
  margin:0;
}
#srp-model-container p.copilot-class {
  font-family:'Geomanist-book';
  font-size:22px;
  font-weight:300;
  color:#263040;
  line-height:29px;
  margin-bottom:0 0 15px;
}
#srp-model-container p.model-description {
  font-family:'Geomanist';
  font-size:22px;
  font-weight:300;
  line-height:32px;
  color:#455266;
  margin:0 0 15px;
}
#srp-model-container #srp-model-images {
  border-radius:5px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
  overflow:hidden;
}
#srp-model-container #srp-model-images img {
  display:none;
  width:100%;
}
#srp-model-container #srp-model-images img.show {display:block;}
#srp-model-container #srp-model-year-tabs {
  overflow:auto;
  padding:15px 0;
  text-align:center;
  white-space:nowrap;
}
#srp-model-container #srp-model-year-tabs a {
  border-radius:15px;
  color:#425268;
  font-family:'Geomanist';
  font-size:16px;
  font-weight:100;
  letter-spacing:1px;
  margin:5px;
  padding:5px 15px;
  text-decoration:none;
  white-space:nowrap;
}
#srp-model-container #srp-model-year-tabs a.on {
  background:#CAD3DF;
}
#srp-model-container #srp-model-year-tabs a i {
  color:#fff;
  font-size:12px;
  margin-right:5px;
}
#srp-model-container #srp-model-year-tabs a.on i{color:#04CB2A}
#srp-model-container #srp-model-year-contents div.srp-model-year {display:none}
#srp-model-container #srp-model-year-contents div.srp-model-year.show {display:block}
#srp-model-container div.model-block {
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
  margin-bottom:25px;
}
#srp-model-container div.model-block a.internal-link {
  color:$fontColor;
  display:block;
  font-family:'Geomanist';
  font-size:14px;
  line-height:24px;
  margin:5px 15px;
  text-decoration:none;
}
#srp-model-container div.model-block h3 {
  background: #E0E6EE;
  color:#425268;
  font-family:'Geomanist';
  font-size:14px;
  letter-spacing:1px;
  margin:0 0 10px;
  padding:5px 15px;
  text-transform:uppercase;
}
#srp-model-container div.model-block h3.flat {margin:0} 
#srp-model-container div.model-block h4 {
  color:$darkGreyColor;
  font-family:'Geomanist';
  font-size:15px;
  font-weight:100;
  margin:0;
  padding:10px 15px;
  text-transform:uppercase;
}
#srp-model-container div.model-block h5 {
  color:$darkGreyColor;
  font-family:'Geomanist';
  font-size:14px;
  font-weight:100;
  margin:0;
  padding:5px 15px 15px;
}
#srp-model-container div.model-block div.model-block-section {
  border-top:1px solid #eee;
}
#srp-model-container div.model-block div.model-columns {
  display: flex;
  flex-wrap: wrap;
}
#srp-model-container div.model-block div.model-columns h4 {
  padding:10px 0;
}
#srp-model-container div.model-block div.model-columns div.model-col {
  padding:0 15px 15px;
  width:33.33%;
}
#srp-model-container div.model-block div.model-columns div.model-col.two {
  padding:0 15px 15px;
  width:50%;
}
#srp-model-container div.model-block div.model-columns div.model-col.two span.green svg,
#srp-model-container div.model-block div.model-columns div.model-col.two span.gray svg { height:25px; width:26px;}
#srp-model-container div.model-block div.model-columns div.model-col.two span.green svg g.rabbit-icon {
  fill:#59BF26
}
#srp-model-container div.model-block div.model-columns div.model-col.two span.gray g.rabbit-icon {
  fill:#CCD3DE
}
#srp-model-container div.model-block div.model-columns div.model-col h5 {
  padding:0;
}
#srp-model-container div.model-block div.model-columns div.model-col h5.cap {
  text-transform:capitalize;
}
#srp-model-container div.model-block div.model-columns div.model-col h5.orange {
  color:#F6610C
}
#srp-model-container div.model-block div.model-columns div.model-col span.no-list {
  display:block;
  color:$fontColor;
  font-family:'Geomanist';
  font-size:14px;
  font-weight:100;
  line-height:22px;
}
#srp-model-container div.model-block div.model-columns div.model-col span.list-item {
  display:list-item;
  color:$fontColor;
  font-family:'Geomanist';
  font-size:14px;
  font-weight:100;
  line-height:22px;
  list-style-type:disc;
  margin-left:15px
}
#srp-model-container div.model-block div.model-columns div.model-col span.list-item.bolded {
  color: #131313;
  font-family:'Geomanist-medium';
}
#srp-model-container div.model-block div.model-columns div.model-col.color {

}
#srp-model-container div.model-block div.model-columns div.model-col.color span {vertical-align:top;}
#srp-model-container div.model-block div.model-columns div.model-col.color span.dot {
  border-radius:17px;
  display:inline-block;
  height:34px;
  margin-right:10px;
  width:34px;
}
#srp-model-container div.model-block div.model-columns div.model-col.color span.name {
  color:$fontColor;
  font-family:'Geomanist';
  font-size:14px;
  font-weight:100;
  line-height:34px;
  white-space:nowrap;
}
#srp-model-container div.model-block table.basics {
  margin:0 15px;
  width:calc(100% - 30px;)
}
#srp-model-container div.model-block table.basics td {
  border-bottom:1px solid #ddd;
  color:#425268;
  font-family:'Geomanist';
  font-size:18px;
  font-weight:100;
  padding:15px 0;
  vertical-align:top;
}
#srp-model-container div.model-block table.basics td strong { color:$darkGreyColor;}
#srp-model-container div.model-block table.basics td.right {text-align:right}
#srp-model-container div.model-block table.basics td svg {
  display:inline-block;
  height:40px;
}
#srp-model-container div.model-block table.basics tr:last-child td {border:0}
#srp-model-container div.model-block div.company {
  padding:5px 15px 15px;
  position:relative;
}
#srp-model-container div.model-block div.model-block-section div.company {
  padding-top:15px;
}
#srp-model-container div.model-block div.company span.logo{
  border-radius:32px;
  border:1px solid #E1E6ED;
  display:inline-block;
  height:62px;
  margin-right:5px;
  width:62px
}
#srp-model-container div.model-block div.company span.logo img {
  display:block;
  margin:0 auto;
  width:50px;
}
#srp-model-container div.model-block div.company span.logo img.nhsta {margin-top:18px}
#srp-model-container div.model-block div.company span.logo img.iihs {margin-top:17px}
#srp-model-container div.model-block div.company span.logo img.jd {margin-top:27.5px}
#srp-model-container div.model-block div.company span.name {
  color:$fontColor;
  display:inline-block;
  font-family:'Geomanist';
  font-size:22px;
  font-weight:100;
  line-height:64px;
  white-space:nowrap;
  vertical-align:top;
}
#srp-model-container div.model-block div.company span.name.top {
  line-height:26px;
}
#srp-model-container div.model-block div.company span.name.top span.flag {
  display:block;
  font-family:'Geomanist';
  font-size:12px;
  letter-spacing:1px;
  margin-top:10px;
  text-transform:uppercase;
}
#srp-model-container div.model-block div.company span.name.top span.flag em {
  background:#eee;
  border-radius:3px;
  color:#000;
  display:inline-block;
  font-style:normal;
  font-weight:100;
  padding:0 8px;
}
#srp-model-container div.model-block div.company span.name.top span.flag em.good {background:#05D303}
#srp-model-container div.model-block div.company span.name.top span.flag em.acceptable {background:#FFFF00}
#srp-model-container div.model-block div.company span.name.top span.flag em.marginal {background:#FF8D02}
#srp-model-container div.model-block div.company span.name.top span.flag em.poor {background:#FF2600}
#srp-model-container div.model-block div.call-out{
  background:#F6F7F9;
  margin:0 15px 15px;
  padding:15px;
}
#srp-model-container div.model-block div.call-out p {
  color:$fontColor;
  font-family:'Geomanist';
  font-size:16px;
  font-weight:100;
  line-height:22px;
  padding:0 0 15px;
  margin:0 0 10px;
}
#srp-model-container div.model-block div.call-out p.smaller {font-size:14px; line-height:20px; margin:0}
#srp-model-container div.model-block div.call-out div.rating-legend {
  display:flex;
  flex-wrap: wrap;
}
#srp-model-container div.rating-legend div.legend-value {
  width:25%;
}
#srp-model-container div.rating-legend div.legend-value span.legend-text {
  color:$fontColor;
  display:inline-block;
  font-family:'Geomanist';
  font-size:16px;
  font-weight:100;
  line-height:32px;
}
#srp-model-container div.rating-legend div.legend-value span.legend-text.smaller {
  font-size:14px;
  line-height:20px;
}
#srp-model-container div.rating-legend div.legend-value span.legend-text.block-level {display:block; padding-bottom:5px;}
#srp-model-container div.rating-legend div.legend-value span.block {
  border-radius:3px;
  color:#000;
  font-family:'Geomanist';
  font-size:16px;
  font-weight:100;
  display:inline-block;
  height:32px;
  line-height:32px;
  margin-right:5px;
  text-align:center;
  width:32px;
}
#srp-model-container div.rating-legend div.legend-value span.block.good {background:#05D303}
#srp-model-container div.rating-legend div.legend-value span.block.acceptable {background:#FFFF00}
#srp-model-container div.rating-legend div.legend-value span.block.marginal {background:#FF8D02}
#srp-model-container div.rating-legend div.legend-value span.block.poor {background:#FF2600}
#srp-model-container div.model-block div.company span.name sup {
  font-size:22px;
  top:0;
}
#srp-model-container #internal-links {
  padding-bottom:10px;
}
#srp-model-container #internal-links h6 {
  color:#425268;
  font-family:'Geomanist';
  font-size:14px;
  padding:10px 15px 5px;
  text-transform:uppercase;
}
div.srp-model-price-range-chart {
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
  display:none;
  margin-bottom:25px;
  position:relative;
  padding:15px;
}
div.srp-model-price-range-chart.nodata {
  border:0;
  box-shadow:none;
  padding:0;
  margin:0;
}
div.srp-model-price-range-chart.show {
  display:block;
}
div.srp-model-price-range-chart div.chart {
  margin:40px auto 0;
  width:70%;
}
div.srp-model-price-range-chart span.price {
  color:#6B798C;
  font-family:'Geomanist';
  font-size:24px;
  font-weight:100;
  left:15px;
  position:absolute;
  top:15px;
}
div.srp-model-price-range-chart.nodata span.price {display:none}
div.srp-model-price-range-chart span.price span.flag,
div.model-details-row span.flag {
  display:block;
  font-family:'Geomanist';
  font-size:14px;
  letter-spacing:1px;
  margin-top:10px;
  text-transform:uppercase;
}
div.srp-model-price-range-chart span.price span.flag em,
div.model-details-row span.flag em {
  background:#E8F1FD;
  border-radius:3px;
  color:#027BF3;
  display:inline-block;
  font-style:normal;
  font-weight:100;
  padding:0 8px;
}
div.srp-model-price-range-chart div.price-range{
  margin:0 auto;
  padding:5px 0 10px;
  overflow:auto;
  width:70%;
}
div.srp-model-price-range-chart.nodata div.price-range {display:none;}
div.srp-model-price-range-chart div.price-range span {
  color:#6B798C;
  font-family:'Geomanist';
  font-size:20px;
  font-weight:100;
  display:block;
  text-align:center;
}
div.srp-model-price-range-chart div.price-range span.low-price {
  float:left;
  text-align:left;
}
div.srp-model-price-range-chart div.price-range span.high-price {
  float:right;
  text-align:right;
}
.stop-left {
  stop-color: #FFA80E;
}
.stop-right {
  stop-color: #FF725A;
}
div.model-details-row {
  border-top:1px solid #eee;
  overflow:auto;
  padding:15px 15px 25px;
}
div.model-details-row.first {border:0; padding-top:5px}
div.model-details-row.bordered {
  border-top:1px solid #eee;
}
div.model-details-row div.left-block {
  float:left;
}
div.model-details-row div.range {
  float:right;
  margin-left:15px;
}
div.model-details-row div.range.seats svg,
div.model-details-row div.range.cases svg {height:38px}
div.model-details-row div.range.cases svg {margin-left:5px;}
div.model-details-row div.range span.green svg,
div.model-details-row div.range span.gray svg {height:38px}
div.model-details-row div.range span.green svg g.rabbit-icon {
  fill:#59BF26
}
div.model-details-row div.range span.gray svg g.rabbit-icon {
  fill:#CCD3DE
}
div.model-details-row div.range span.values,
div.model-details-row div.range span.title {
  color:$fontColor;
  display:block;
  font-family:'Geomanist';
  font-size:24px;
  font-weight:100;
  text-align:right;
}
div.model-details-row div.range span.title {font-size:14px;}
div.model-details-row div.range.lrg span.values {
  font-size:32px;
}
div.model-details-row span.text {
  color:#6B798C;
  font-family:'Geomanist';
  font-size:20px;
  font-weight:100;
}
div.model-details-row span.text.blue {color:#425268;}
div.model-details-row span.text strong {font-weight:400}
div.model-details-row span.flag {margin:0;}
div.model-details-row span.flag.mobile {display:none;}
div.model-details-row span.flag.average em{
  color: #2f7aeb;
  background: rgba(47, 122, 235, 0.1)
}

div.model-details-row span.flag.above_average em,
div.model-details-row span.flag.spacious em,
div.model-details-row span.flag.zippy em,
div.model-details-row span.flag.better_than_most em{
  color: #48c748;
  background: rgba(72, 199, 72, 0.1);
}

div.model-details-row span.flag.below_average em,
div.model-details-row span.flag.tight em,
div.model-details-row span.flag.sluggish em,
div.model-details-row span.flag.gas_guzzler em {
  color: #6e798a;
  background:#f0f3f7;
}

div.model-details-row span.flag.best_in_class em{
  color:#fff;
  background:#48c748;
}
p.quickness-legend {
  color:$fontColor;
  display:block;
  font-family:'Geomanist';
  font-size:12px;
  font-weight:100;
  padding:15px 15px 10px;
}



