.with_background {
  background-color: $backgroundColor;

  .with_background {
    background-color: #fff;
  }
}

.with_padding {
  padding: 15px;
}

.with_border {
  border: 1px solid $borderColor;
}

.with_top_border {
  border-top: 1px solid $borderColor;
}

.preload_background {
  background-repeat: repeat !important;
  background-color: transparent !important;
  background-attachment: scroll !important;
  background-size: inherit !important;
  animation-name: preload_background;
  animation-duration: 40s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  background-image: url("/img/preload_pattern.png");
}

@media (min-width: 768px) {
  .two_cols {
    column-count: 2;
  }
}

.oswald {
  font-family: "Oswald", sans-serif;
}

.important {
  font-weight: 700;
  font-size: 1.1em;
  color: $darkGreyColor;
}

.darklinks {
  a {
    text-decoration: none;
    color: $darkGreyColor;

    &:hover {
      color: $mainColor;
    }
  }
}

.highlight {
  color: $mainColor;
}

.highlight2 {
  color: $mainColor2;
}

.margin_0 {
  margin: 0 !important;
}
.margin_top_10 {
  margin-top: 10px;
}
.margin_top_20 {
  margin-top: 20px;
}
.text-center-link{
  text-align: center;
}
.no-underline-link{
  text-decoration: none;
  color: #f6610c;
  font-family: 'Geomanist-book';
}
.margin_top_30 {
  margin-top: 30px;
}
.margin_top_40 {
  margin-top: 40px;
}
.margin_top_50 {
  margin-top: 50px;
}
.margin_top_75 {
  margin-top: 75px;
}
.padding_top_10 {
  padding-top: 10px;
}
.padding_top_20 {
  padding-top: 20px;
}
.padding_top_30 {
  padding-top: 30px;
}
.padding_top_40 {
  padding-top: 40px;
}
.padding_top_50 {
  padding-top: 50px;
}
// .color_overlay {
//   position: relative;

//   &:before {
//     content: "";
//     position: absolute;
//     top: 0;
//     right: 0;
//     left: 0;
//     bottom: 0;
//   }

//   > * {
//     position: relative;
//     z-index: ;
//   }
// }

.teaser_icon {
  max-width: 180px;
  margin: 0 auto;
  position: relative;
}

// .teaser_row {
//   display: flex;
//   margin: 0 -25px;

//   > * {
//     margin: 0 25px;
//     text-align: center;
//     max-width: calc(33.3333333% - 50px);
//   }
// }

.main_bg_color {
  background-color: $mainColor;
}

.main_bg_color2 {
  background-color: $mainColor2;
}

.dark_bg_color {
  background-color: $darkGreyColor;
}

.dark_bg_color,
.main_bg_color,
.main_bg_color2 {
  color: #fff;
}

.hero_text {
  font-size: 30px;
  line-height: 1.3em;
  font-family: "Oswald", sans-serif;

  @media (min-width: 992px) {
    font-size: 36px;
  }

  @media (min-width: 1200px) {
    font-size: 42px;
  }
}
