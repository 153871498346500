.post_header {
  margin-bottom: 30px;

  time {
    display: inline-block;
    margin-right: 4px;
  }

  address {
    display: inline-block;
    margin: 0;
  }

  h1.post_title {
    font-size: 2.5rem;
    margin-bottom: 0.2em;
  }
}

.header_meta {
  a {
    text-decoration: none;
  }
}

h2.post_subtitle {
  font-size: 2rem;
  margin-top: 0;
  color: darken($mainColor2, 20);
}

.post_thumbnail {
  margin-bottom: 30px;

  // @media (min-width: 992px) {
  //   max-width: 130%;
  //   width: 130%;
  //   margin-left: -15%;
  // }

  // .frame-content & {
  //   max-width: 130%;
  //   width: 130%;
  //   margin-left: -15%;
  // }
}

.post_content {
  hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .widget-cta {
    margin-top: 40px;
    margin-bottom: 50px;
  }
}

.post_action {
  text-align: center;
  background-color: #f6f7f7;
  padding: 15px;

  .qr-image {
    margin-top: 40px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .title {
    font-family: "Geomanist-book", sans-serif;
    color: $darkGreyColor;
    font-size: 1.5em;
  }

  @media (min-width: 576px) {
    padding: 30px;
  }

  .widget & {
    margin: 0;
  }

  .cta_ga_click_tracker{
    font-family: "Geomanist";
    background-color: #f79726;
    border-color: #f79726;
  }
}

.sibsrcibe_form {
  input {
    width: 300px;
    max-width: 100%;
    display: block;
    margin: 0 auto 15px;
    text-align: center;
  }
}

.social_block {
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  padding: 20px;
  text-align: center;

  .post + & {
    margin-top: 30px;
  }
}

.post_author {
  .social_block + &,
  .post + & {
    margin-top: 30px;
  }

  .author_image {
    max-width: 200px;
    border-radius: 50%;
    margin: auto;

    img {
      border-radius: 50%;
    }
  }

  .author_name {
    display: block;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    color: $darkGreyColor;
    line-height: 1em;
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  .author_meta {
    margin-top: 20px;
  }

  @media (min-width: 576px) {
    .author_meta {
      margin-top: 0;
    }
  }
}

.page_item {
  h3 {
    margin-top: 1em;
    margin-bottom: 0.8em;
    font-size: 1.75rem;
  }
}
