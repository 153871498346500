$si-bar-delay: 0.0s;
$si-bar-change-speed: 0.3s;

.bar-steps-iterator-container{
  width: 100%;
  height: 50px;

  margin: 0 auto;
  transform: translateZ(0);

  .steps-progress-bar-container{
    margin-top: 10px;
    position: relative;


    .empty-bar{
      position: absolute;
      top: 1px;
      height: 1px;
      border-bottom: 1px solid #0000003D;
      width: 100%;

    }
    .active-bar{
      position: absolute;
      top: 0px;
      height: 1px;
      border-top: 1.5px solid #2641a2;

      transition:left $si-bar-change-speed linear;
      transition-delay: $si-bar-delay;

      // WIDTH FOR ACTIVE BAR IS SET IN SCRIPT
      // LOCATION FOR ACTIVE BAR IS SET IN SCRIPT
    }


  }
}

@keyframes laststep{
  0% {transform: scale(1, 1);}
  50% {transform: scale(1.048, 1.048);}
  100% {transform: scale(1, 1);}
}