section.sitemap {
  padding:25px 0 100px;
}

div.sitemap-tabs {}

div.sitemap-tabs a {
  display:inline-block;
  margin-right:15px;
  text-decoration: none;
  text-transform: capitalize;
}
div.sitemap-tabs a span {
  border-bottom:3px solid #fff;
  display:block;
  padding-bottom: 15px;
}
div.sitemap-tabs a.on span {
  border-color:#ef4123
}
div.sitemap-links {
  border-top:1px solid #eee;
  overflow:auto;
  padding:15px 0 25px;
}
div.sitemap-column {
  float:left;
  width:50%;
}
a.sitemap-link {
  display: block;
  float: left;
}
a.sitemap-link.block {
  float:none;
}
a.sitemap-link.wide-3 {
  width:33%
}
