.ds {
  color: $dsFontColor;
  background-color: #131313;

  &.parallax {
    color: rgba(#fff, 0.85);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }

  a {
    &:hover {
      color: #fff;
    }
  }

  .greylinks {
    a {
      color: $dsFontColor;

      &:hover {
        color: #fff;
      }
    }
  }

  .dark {
    color: #fff;
  }

  /* flexslider */
  .flex-control-paging {
    li {
      a {
        background-color: rgba(#fff, 0.5);

        &:hover,
        &.flex-active {
          background-color: rgba(#fff, 1);
        }
      }
    }
  }

  /* breadcrumbs */
  .breadcrumbs {
    a {
      &:hover {
        color: $mainColor;
      }
    }
  }
}
