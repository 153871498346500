#srp-make-container {
  padding:25px;
}

#srp-make-container h1 {
  margin-bottom:15px;
}

#srp-make-container p.srp-make-description {
  margin-bottom:25px;
  white-space:pre-wrap;
}

#srp-make-container #srp-make-type-tabs {
  text-align:center;
  overflow:auto;
  white-space:nowrap;
}

#srp-make-container #srp-make-type-tabs a {
  color:#7C8899;
  display:inline-block;
  font-size: 20px;
  font-family: "Geomanist"; 
  font-weight:300;
  margin:0 15px;  
  text-align:center;
  text-decoration:none;
}
#srp-make-container #srp-make-type-tabs a span {
  border-bottom:2px solid #fff;
  display:inline-block;
  padding-bottom:5px;
}
#srp-make-container #srp-make-type-tabs a:hover span,
#srp-make-container #srp-make-type-tabs a.on span {
  border-color:#7C8899;
}

#srp-category-types {
  padding:15px 0 25px;
}

#srp-category-types div.make-category-wrapper {
  display:none;
}

#srp-category-types div.make-category-wrapper.show {
  display:block;
  margin:0 auto;
  width:1034px; 
}

#srp-category-types div.make-category-wrapper div.make-model-row {
  display:flex;
  flex-wrap:nowrap;
  overflow:auto;
  margin-bottom:25px;
  padding-bottom:20px;
}

#srp-category-types div.make-category-wrapper a {
  color:#fff;
  display:block;
  margin-right:30px;
  text-decoration:none;
  width:296px;
}

#srp-category-types div.make-category-wrapper a span.link-body {
  display:block;
  margin:auto;
  position:relative;
  width:296px;
}
#srp-category-types div.make-category-wrapper a img {
  border-radius:20px;
  height:200px;
  width:296px;
}
#srp-category-types div.make-category-wrapper a span.generation-title {
  bottom:5px;
  font-family: "Geomanist"; 
  font-size:16px;
  font-weight:300;  
  position:absolute;
  left:20px;
}

#srp-make-vlp-internal-links,
#srp-make-something-else {
  padding:100px 0 50px;
  h4 {
    text-align:center;
  }
  div {
    display:flex;
    flex-wrap:wrap;
  }
  div a {
    color:$darkGreyColor;
    display:block;
    font-family: "Geomanist"; 
    font-size:16px;
    font-weight:300;    
    padding:10px 0;
    text-decoration:none;
    width:calc(100%/8);
  }
  div a:hover {
    color:var(--header-orange);
  }
}
#srp-make-vlp-internal-links {
  div a {
    width:calc(100%/3);
  }
}

p.model-overview-cta-wrapper {
  position: -webkit-sticky;
  bottom:-60px;
  background:#fff;
  padding:10px 0;
}

p.model-overview-cta-wrapper a {
  background:#F6610C;
  border-radius:8px;
  color:#fff;
  display:block;
  font-family: "Geomanist-book"; 
  font-size:24px;
  height:72px;
  letter-spacing:0.28px;
  line-height:72px; 
  margin:15px auto 75px;
  text-align:center;
  text-decoration:none;
  width:490px;
}

