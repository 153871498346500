html {
  @media (max-width: 480px) {
    zoom: 0.865;
  }
  @media (max-width: 438px) {
    zoom: 0.81;
  }
  @media (max-width: 410px) {
    zoom: 0.72;
  }
  @media (max-width: 374px) {
    zoom: 0.525;
  }
  @media (max-width: 285px) {
    zoom: 0.5;
  }
}

.site_intro {
  background-color: $ml_mainWhite;
  border-color: $ml_mainWhite;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 50px;

  .site-link {
    font-size: 16px;
  }

  &.intro_section.ml {
    background-color: $ml_mainWhite;
    width: 100%;
    min-width: 100%;

    .container {

      .row {
        .column-header, .text-column {
          padding-left: 25px;
          padding-right: 25px;
        }
        .column-header {
          max-width: unset;
          margin: 100px auto 0px auto;

          .oswald {
            &.title {
              color: $ml_mainWhite;
              font-weight: bold;
              font-family: "Geomanist", sans-serif;
              line-height: 1.125;
              font-size: 30px;
            }
          }
        }
        .text-column {
          margin: 0 auto;
        }
      }
    }
  }

  &#site-intro {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("/img/Homepage-hero-2.jpeg"), linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("/img/Homepage-hero-mini-2.jpeg");
    background-position: center center;

    &.intro_section.ml {  
      .outer-container {
        padding: 40px 57px;
        .inner-container {
          max-width: 1140px;
          min-width: 450px;
          margin: 0 auto;
          .left-side {
            padding: 45.6px;
            background-color: #FFFFFF;
            .header {
              display: flex;
              align-items: center;
              font-size: 3rem;
              color: #f6610c;
              font-family: "Geomanist", sans-serif;
              padding-bottom: 0;
              line-height: 1.12em;
              padding-top: 2rem;
              .spacer {
                width: 18px;
                height: 2px;
                background-color: #f6610c;
                margin: 0 8px;
              }
            }
            .subheader {
              color: #000000;
              font-size: 1.5rem;
              font-family: "Geomanist", sans-serif;
              line-height: 1.25;
              padding-bottom: 2rem;
            }
            .list > ul {
              color: #000000;
              list-style: none;
              padding: 0px;
              font-family: "Geomanist", sans-serif;
              font-size: 1.5rem;
              padding-bottom: 1.5rem;
              li {
                margin-bottom: 0.5rem;
                i {
                  margin-right: 0.5rem;
                }
              }
            }
          }
          .right-side {
            background-color: #EFEDEC;
            padding: 45.6px;
            .content-container {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              video {
                width: 75%;
                max-height: 450px;
              }
              .header {
                font-size: 32px;
                color: #000000;
                margin: 18px 0px;
                font-family: "Geomanist", sans-serif;
              }
              .blurb {
                font-family: "Open Sans", sans-serif;
                font-size: 1.125rem;
                line-height: 1.25;
                max-width: 335px;
                text-align: center;
                color: #000000;
              }
              .btn-container {
                display: none;
              }
            }
          }
        }
      }
      @media (max-width: 930px) {
        .outer-container{
          padding: 10vw 5vw;
          .inner-container {
            flex-direction: column;
            .left-side, .right-side {
              flex-basis: 100%;
              max-width: 100%;
            }
            .homepage-header {
              .header {
                padding-top: 0;
                padding-bottom: 0.666rem;
                font-size: 32px;
                padding-bottom: 8px;
              }
              .subheader {
                padding-bottom: 19px;
              }
              .list ul {
                padding-bottom: 0;
              }
              .btn-container {
                padding: 20px 0 0 0;
              }
            }
          }
        }
        @media (max-width: 767px) {
          .outer-container {
            padding: 10vw 5vw;
            .inner-container {
              flex-direction: column;
              .left-side, .right-side {
                padding: 2rem;
              }
              .left-side {
                .header {
                  font-size: 32px;
                  padding-bottom: 8px;
                }
                .subheader {
                  font-size: 19px;
                  padding-bottom: 20px;
                }
                .list > ul {
                  font-size: 16px;
                  line-height: 24px;
                  li > i {
                    font-size: 240x;
                  }
                  li {
                    margin-bottom: 0;
                  }
                }
                .btn-container {
                  display: none;
                }
              }
              .right-side {
                .content-container {
                  .blurb {
                    font-size: 1rem;
                    padding: 0px 15px;
                  }
                  .btn-container {
                    margin-top: 2rem;
                    display: block;
                    padding: 8px;
                  }
                }
              }
            }
          }
        }
  
        // Galaxy fold :facepalm:
        @media (max-width: 390px) {
          .outer-container{
            .inner-container {
              .left-side {
                .header {
                  font-size: 32px;
                  padding-bottom: 8px;
                }
              }
            }
          }
        }
      }
    }
  }

  .title {
    display: block;
    margin-bottom: 0.7em;
    line-height: 1;
  }
}

#how-copilot-works {
  background-color: rgba(239,237,236,0.5);
  .ml-how-copilot-works-padding {
    width: 100%;
    padding: 45px 0px;
    .how-works-text {
      text-align: center;
      .section_header {
        font-size: 28px;
        font-family: 'Geomanist-book', sans-serif;
        font-weight: bold;
      }
      .ml-how-copilot-works-subhead {
        font-size: 20px;
      }
    }
    .steps {
      .step {
        color: #000000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        i { 
          font-size: 60px;
          color: $ml_mainOrange;
          max-width: 60px;
          margin-bottom: 20px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

#own,
#financing-and-insurance,
#ai-powered-copilot {
  max-width: 1140px;
  min-width: 450px;
  margin: 0 auto;
  .text-column-container {
    margin: 0 auto;
    width: 100%;
    .oswald {
      font-size: 24px;
      line-height: 1.45;
    }
    .image-container {
      display: flex;
      align-items: center;

      .multi-img-container {
        width: 100%;
      }
    }
    .text-column {
      .list > ul {
        list-style: none;
        padding: 0px;
        font-size: 1.25rem;
        color: #000000;
        margin-bottom: 35px;
        li {
          margin-bottom: 0.5em;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 24px;
          font-family: "Geomanist", sans-serif;
          span {
            font-weight: bold;
          }
        }
      }
    }
  }
}

#apple-reviews {
  .apple-reviews-container {
    width: 800px;
    margin: 60px auto;

    .apple-reviews-img {
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      background-image: url("/img/apple-reviews-desktop.png");
      position: relative;
      margin: 0 auto;
      height: 0;
      padding-bottom: 35%;
    }

    .btn-container {
      text-align: center;
      margin-top: 35px;
    }
  }

  @media (max-width: 767px) {
    .apple-reviews-container {
      width: 400px;

      .apple-reviews-img {
        max-width: 400px;
        background-image: url("/img/apple-reviews-mobile.png");
        padding-bottom: 150%;
      }
    }
  }
}

#ai-page-banner {
  padding: 60px 24px 0px 24px;
  text-align: center;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    border-radius: 12px;
    max-width: 800px;
    margin: 0px auto;
  }
}

.intro_section,
#copilot-owning_section {
  .inner-container {
    max-width: 1140px;
    min-width: 450px;
    margin: 0px auto;
  }
  .title-row {
    margin-bottom: 25px;
    .lined-title-container {
      h2 { color: #000000; }
    }
  }

  .owning-img {
    width: 100%;
    max-width: 455px;
    margin: 0 auto;
    text-align: center;
  }

  .owning-checklist {
    .subtitle {
      margin-bottom: 15px;
      h4 {
        color: #000;
      }
    }
    .list > ul {
      list-style: none;
      padding: 0px;
      color: #000000;
      font-size: 1.25rem;
      font-family: "Geomanist", sans-serif;
      li {
        margin-bottom: 1em;
        line-height: 1.375;
        padding: 0px;
        span {
          font-family: "Geomanist";
          font-weight: 900;
        }
      }
    }
    .btn-container-with-qr {
      text-align: left;
      a {
        border-radius: 50px;
        max-width: 210px;
      }
      @media (max-width: 768px) {
        a {
          max-width: 180px;
        }
      }
    }
    @media (max-width: 768px) {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  &.ml {
    background-color: $ml_mainWhite;
    width: 100%;
    min-width: 100%;

    .container {
      padding: 18px 0px;

      &.cc {
        margin: unset;
        padding: unset;
      }
      .row {
        &.blue {
          margin: unset;
          background-color: #dbdfed;
        }
        .inner-row {
          background-color: white;
          margin: 37px;
          width: 100%;
          padding: 29px 29px 40px;
          text-align: left;
          border-radius: 8px;

          .copilot-compare-title {
            font-family: Geomanist-Book;
            font-size: 16px;
            line-height: 1.63;
            color: #2641a2;
            margin-bottom: 12.2px;
          }

          p.ml-highlight .highlight {
            font-size: 14px;
            line-height: 1.57;
          }
        }
      }
    }

    .image-container {
      position: relative;
      width: 100%;
      margin-top: 30px;

      .img-max-container {
        max-width: 500px;
        margin: 0 auto;
        position: relative;
      }

      .multi-img-container {
        width: 90%;
        margin: 0 auto;
        z-index: 2;

        .vdp-img {
          width: 100%;
        }
      }

      .own-family,
      .finance-and-insurance{
        width: 450px;
        position: relative;
        margin: 0 auto;
        overflow: hidden;
      }
      .own-family {
        .copilot-for-buying-img {
          background-size: contain;
          background-repeat: no-repeat;
          width: 100%;
          position: relative;
          margin: 0 auto;
          height: 0;
          background-image: url("/img/copilot-for-buying.png");
          padding-bottom: 70%;
        }
      }

      @media (max-width: 767px) {
        .own-family {
          .copilot-for-buying-img {
            background-image: url("/img/copilot-for-buying-mobile.png");
            max-width: 400px;
            height: 568px;
          }
        }
      }
    }

    .ml-row{
      width: 100%;
    }

    .oswald{
      font-family: Geomanist;
      color: #000000;
      text-align: left;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      text-align: left;

      &.title{
        font-size: 36px;
        line-height: 46px;
        letter-spacing: -0.5px;
        margin-top: 33px;
      }
      &.subtitle {
        font-size: 24px;
        line-height: 1.45;
        letter-spacing: normal;
        margin: 34px 0 22px;
      }
    }
    .know-more-list {
      list-style: none;
      text-align: left;
      padding: 0px;
      color: #000000;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 25px;
      font-family: "Geomanist", sans-serif;
      li {
        margin-bottom: 24px;
        i {
          margin-right: 10px;
        }
      }
    }

    .text-column{
      margin: 0 auto;

      &.ml-padding{
        padding-left: 32px;
        padding-right: 32px;

        .text-center-link {
          height: 38px;
          line-height: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .button {
    border-radius: 25px;
    background-color: $ml_mainOrange;
    color: $ml_mainWhite;
    border-color: $ml_mainOrange;
    font-family: Geomanist-book;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-size: 20px;
    min-height: 60px;
    position: relative;
    min-height: 60px;
    min-width: 120px;
    padding: 20px 32px;
    border-radius: 60px;
    text-transform: none;
    width: calc(100%);
    width: 300px;
    margin: 0px auto;
    transition: none;
  }


  @media (min-width: 1200px) {
    p {
      font-size: 20px;
    }

    .title {
      font-size: 80px;
    }
  }

  .container {
    padding: 18px 15px;

    @media (min-width: 992px) {
      padding-top: 56px;
      padding-bottom: 100px;
    }

    @media (min-width: 992px) {
      padding-top: 76px;
      padding-bottom: 120px;
    }
  }

  .slides {
    display: flex;

    > li {
      min-height: 100%;
    }
  }

  .slides > li {
    position: relative;
  }

  .slide_media {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;

    > img {
      width: 100%;
    }
  }

  .flexslider {
    .flex-control-nav {
      position: absolute;
      bottom: 30px;
      left: 0;
      right: 0;
    }
  }
  
  // Lined Title
  .lined-title-container {
    width: 100%;
    padding: 25px 32px 10px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .emphasize-line {
      height: 2px;
      max-height: 2px;
      width: 100%;
      background-color: $ml_mainOrange;
    }
    
    @media (max-width: 768px) {
      flex-direction: column;
      padding-bottom: 45px;
      h2 {
        margin: 15px;
      }
    }

  }

  .text-column-container {
    width: 100%;
    padding-bottom: 25px;
    .image-container {
      margin: 0 auto;
      .img-max-container {
        display: flex;
        align-items: center;
        .multi-img-container {
          max-width: 500px;
          min-width: 325px;
        }
      }
    }
  }
  
  .lined-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    span {
      padding:0 10px;
      font-size: 36px;
      min-width: 250px;
    }
  }

  @media (max-width: 666px) {
    .lined-title span {
      font-size: 27px;
    }
  }
  // End of lined Title
}

#as-seen-on-tv.intro_section.ml {
  max-width: 1140px;
  min-width: 450px;
  margin: 0 auto;
  padding-bottom: 35px;
  .container {
    padding-left: 15px;
    padding-right: 15px;
    .btn-container {
      .button {
        max-width: 227px;
      }
    }
  }
  .lined-title-container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .text-column-container {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .step {
    box-sizing: border-box;

    .step-video {
      iframe {
        width: 100%;
        height: 196px;
      }
    }
    .copy {
      width: 100%;
      font-family: Roboto;
      color: #000000;

      p {
        padding-top: 10px;
        font-size: 18px;
      }

      .title {
        padding-top: 10px;
        font-weight: 600;
        font-size: 18px;
      }

      .icons {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 20px;

        &#with-msnbc-logo {
          justify-content: space-between;
          padding-left: 15px;
          padding-right: 15px;

          #msnbc-logo {
            width: 152px;
          }
        }

        .icon {
          width: 33%;

          img {
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            max-width: 150px;
          }
        }
      }
    }
  }

  @media (min-width: 1000px) {

  }

  @media (max-width: 999px) {
    .step {
      margin-bottom: 50px;
    }
    .button {
      margin: 30px auto;
    }
  }

  // tablet view
  @media (min-width: 576px) and (max-width: 992px) {
    .step {
      margin-left: auto;
      margin-right: auto;

      .step-video iframe {
        height: 300px;
      }

      .copy .icons#with-msnbc-logo #msnbc-logo  {
        width: 250px;
        max-width: 250px;
      }
    }
  }
}

.text-center-block {
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
}

section.search-in-your-area {
  .text-center-block {
    padding: 0 40px;
  }
  .section_header {
    text-align: left;
    margin-bottom: 40px;
    &.soft-hide {
      visibility: hidden;
      user-select: none;
      height: 0;
      margin: 0;
    }
  }
  .styled-select {
    & > select {
      padding: 16px;
    }
    & > .styled-select-arrow-container {
      position: absolute;
      width: 17px;
      height: 8px;
      top: 21px;
      right: 10px;
      overflow: hidden;
      & > .styled-select-arrow {
        position: absolute;
        top: -8px;
        left: 0;
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        border: 2px solid #f6610c;
        transform: rotate(45deg);
        background: #fff;
      }
    }
    &::after {
      display: none;
      content: initial;
      font-family: initial;
    }
  }
  .action-button {
    width: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .intro_section {
    &.ml {
      .container {
        padding: unset;
      }

      .row {
        position: relative;

        .inner-row {
          margin: 37px;
          width: 100%;
          padding: 29px 29px 40px;
          text-align: center;

          .copilot-compare-title {
            font-size: 20px;
            margin-bottom: 12.2px;
            text-align: center;
          }

          .text-column {
            max-width: unset;
          }

          p.ml-highlight {
            text-align: center;

            .highlight {
              font-size: 20px;
              line-height: 1.8;
              margin-bottom: 0;

              &.extra-bot {
                margin-bottom: 25px;
              }
            }
          }
        }
      }

      .text-center.ml-padding {
        max-width: unset;
        padding-left: 70px;
        padding-right: 70px;
      }

      .button {
        margin-top: unset;
        max-width: 227px;
        font-size: 1.25rem;
        line-height: 1.75rem;
        padding: 14px 34px;
        font-family: Geomanist;
      }

      .image-container {

        .img-max-container {
          max-width: unset;
          margin: 0;
          position: relative;
        }


        .THE-HAND-HIMSELF {
          height: 450px;
          width: 100%;
          margin: 0;
        }
      }

      &#how-copilot-works {
        .ml-how-copilot-works-padding {
          max-width: 1140px;
          min-width: 450px;
          margin: 0 auto;
          width: 100%;
          .ml-how-copilot-works-subhead{
            opacity: 1;
            max-width: 500px;
            margin: 0 auto;
          }
          .copy {
            width: 90%;
            .title {
              font-size: 16px;
            }
            .border-line {
              display: none;
            }
          }

          .steps {
            padding-bottom: 25px;
          }
        }
        .text-center-link {
          font-size: 16px;
        }
      }

      &#how-copilot-works, &#shop {
        .text-center-link {
          max-width: 96%;
          margin: 0 auto;
          text-align: right;
          display: block;
        }
      }
    }

    &#ml_testimonials {
      .testimonial-title {
        font-size: 30px;
      }
      .cc-flexslider {
        max-height: 300px;
      }
      .bar-steps-iterator-container .steps-progress-bar-container .active-bar {
        border-top: 3px solid #2641a2;
      }
    }
  }
}

@media (min-width: 1000px) {
  .intro_section {
    &.ml {
      .text-column-container {
        margin: 0 auto 72px;
        width: 90%;

        .text-column {
          max-width: unset;

          &.ml-padding {
            padding-left: unset;
            padding-right: unset;
          }

          .ml-highlight {
            .highlight {
              font-size: 20px;
              line-height: 30px;
            }

            .sub-highlight {
              font-size: 20px;
              line-height: 30px;
              opacity: 0.5;
              margin-bottom: unset;
            }

            &.extra-bot {
              margin: 24px 0;
            }
          }
        }

        .text-column,
        .image-container {
          display: inline-block;
          width: 49%;
          padding: unset;
          vertical-align: middle;
        }
      }

      &#shop {
        max-width: 1140px;
        min-width: 450px;
        margin: 0 auto;
        .text-column-container {
          margin: 0 auto 0;

          .text-column {
            vertical-align: top;
          }
        }
      }

      .DRAMATIC-GRADIENT {
        &.ds {
          display: block;
        }
        &.hide {
          display: none;
        }
      }

      &#how-copilot-works {
        .container {
          padding-bottom: 0;
        }
        .ml-how-copilot-works-padding {

          .text-center-link{
            margin-top: 41px;
          }

          .section_header{
            font-size: 40px;
          }

          .ml-how-copilot-works-subhead{
            font-size: 25px;
            max-width: 678px;
          }

          .steps {
            padding-bottom: 0;
          }

          .step {
            width: calc(33% - 4px);
            padding: 10px;
            box-sizing: border-box;
            .copy {
              width: 100%;
            }
            .icons .icon {
              width: 32px;
              height: 32px;
            }
          }

        }
      }
    }
  }
}

.makes-money {
  margin: 34px 0 98px;
  &__content {
    padding: 0 15px;
    @media (min-width: 768px) {
      padding: 0 70px;
      text-align: center;
    }

    @media (min-width: 1000px) {
      padding:0;
    }
  }
  &__subhead#makes-money__subhead{
    color: #000;
    line-height: 32px;
    font-size: 22px;
    @media (min-width: 1000px) {
      line-height: 38px;
      font-size: 25px;
      max-width: 695px;
      margin: 0 auto;
    }
    @media (min-width: 1200px) {
      font-size: 25px;
    }
  }

  @media (min-width: 1000px){
    margin-top: 0;
  }
}

.intro_section.ml {
  width: 100%;
  .container {
    margin: 0px;
    min-width: 100%;
    .row {
      margin-right: unset;
      margin-left: unset;
    }
  }
}

.btn-container-with-qr {
  button {
    background-color: #f6610c;
    color: #FFFFFF;
    font-size: 1.25rem;
    border: none;
    padding: 15px 40px;
    border-radius: 50px;
    font-size: 1.25rem;
    margin-right: 2rem;
    width: 300px;
  }
  img {
    height: 60px;
    margin-left: 35px;
  }

  @media (max-width: 930px) {
    .button {
      width: 300px;
      font-size: 20px;
    }
  }

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    .button {
      margin: 0px 0px 15px 0px;
    }
    img { display: none;}
  }

  &#btn-container-with-learn-more-cta {
    .button {
      border-color: #00b120;
      background-color: #00b120;
    }
  }

  .ai_powered_cta {
    margin-bottom: 30px;
  }
}

