.internal_link_container {
  display: flex;
  flex-wrap: wrap;

  a {
    color: $darkGreyColor;
    display:block;
    font-family: 'Geomanist';
    text-align:center;
    text-decoration: none;

    &:hover {
      color: $mainColor;
    }

  }
}

.internal_link {
  width:33%;
}
