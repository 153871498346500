$price-pulse-header-img-height: 18px;
$price-pulse-header-spacing: 15px;
$price-pulse-header-background-color: #F2F2F2;

#site_header_price_pulse {
    background-color: $price-pulse-header-background-color;
    border-bottom: 1px solid #f6610c;
    padding: 10px 16px;
    .site_logo {
        width: 100%;
        a {
            display: flex;
            align-items: center;
            img {
                margin-top: 5px;
                margin-right: 15px;
            }
            .copilot-logo-svg-price-pulse {
                background-image: url(/svg/price-pulse-logo.svg);
                background-size: contain;
                background-repeat: no-repeat;
                width: 100%;
                height: 25px;
                margin-left: 15px;
            }
            .divider {
                margin: 0px 10px;
                width: 1px;
                background-color: #C5C5C5;;
                height: 27px;
            }
        }
    }
}