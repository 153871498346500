html,
body,
#root,
.app-container,
.app-sub-container {
  background: #FFFFFF !important;
}

.price-pulse {
    margin-top: 32px;
    position: relative;
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 25px;
    padding-right: 25px;

    &-tools {
        &-text-container {
            margin: 1vw 0px;
            padding: 16px;
            text-align: left;
            max-width: 1140px;
            width: 100%;

            .price-pulse-title,
            .price-pulse-text {
                margin: 0 auto;
                font-family: "Geomanist", sans-serif;
                color: #455266;
                -webkit-font-smoothing: antialiased;
                -ms-text-size-adjust: 100%;
                -webkit-text-size-adjust: 100%;
            }
            .price-pulse-title {
                font-weight: bold;
                line-height: 30px;
                padding-bottom: 0.5em;
            }
            .price-pulse-text {
                line-height: 1.375;
                font-size: 1.25rem;
                font-weight: 400;
                margin-bottom: 0.75em;
            }
        }
        &-gif-container {
            width: 100%;
            margin: 0px auto 16px auto;
            display: flex;
            align-items: center;
            justify-content: center;
            video {
                max-width: 250px;
                width: 100%;
                cursor: pointer;
            }
        }
    }
    .ml-form-container {
        margin-bottom: 32px;

        .price-pulse-button-container {
            margin: 0 auto;
            width: 100%;

            .ml-button-container {
                margin: unset;
            }

            .price-pulse-cta-to-home, .price-pulse-cta-to-hpc {
                color: #f6610c;
                font-size: 16px;
                text-decoration: underline;
                text-align: center;
                -webkit-font-smoothing: antialiased;
                -ms-text-size-adjust: 100%;
                -webkit-text-size-adjust: 100%;
                margin: 16px;
                opacity: 0.8;
            }
        }

        .price-pulse-ios-app-reviews-container {
            margin: 50px 0px;
        }
    }

    @media (max-width: 787px) {
        margin-top: 24px;

        &-tools {
            &-text-container {
                .price-pulse-title {
                    font-size: 1.875rem;
                }
            }
        }
    }
}

.how-it-works {
    background: #f8f8f8;

    .container {
        max-width: 1140px;
        margin-right: auto;
        margin-left: auto;
        padding-top: 68px;
        padding-right: 57px;
        padding-bottom: 68px;
        padding-left: 57px;    

        .inner-column {
            padding-top: 39px;
            padding-right: 39px;
            padding-bottom: 39px;
            padding-left: 39px;
            background: #FFFFFF;

            .how-it-works-title, .how-it-works-text {
                margin: 0 auto;
                font-family: "Geomanist", sans-serif;
                color: #455266;
                -webkit-font-smoothing: antialiased;
                -ms-text-size-adjust: 100%;
                -webkit-text-size-adjust: 100%;
            }

            .how-it-works-title {
                padding-bottom: 0.6875em;
            }

            .how-it-works-text {
                line-height: 1.625;
                font-size: 1.125rem;
                margin-bottom: 1em;
            }
        }
    }

    @media (max-width: 787px) {
        .container {
            padding: 25px;
        }
    }

}