/** Vehicle details overlay */

.vdp-media {
  overflow: hidden;
  padding: 10px;
  margin-bottom: 20px;
  background-color: $backgroundColor;
  border: 1px solid $borderColor;
  border-radius: $borderRadius;
}

.vdp-carousel {
  margin-top: 5px;
  .slides {
    li {
      opacity: 0.7;
      cursor: pointer;

      &.flex-active-slide,
      &:hover {
        opacity: 1;
      }
    }
  }
}

.vdp-slider,
.vdp-carousel {
  .flex-direction-nav {
    .flex-prev,
    .flex-next {
      background-color: $mainColor;
    }
  }
}

.vdp-header {
  h1 {
    font-size: 2rem;
    margin-bottom: 0;
  }

  .vdp-price-mileage {
    margin-top: 10px;
    margin-bottom: 10px;
    color: $darkGreyColor;

    .price {
      font-size: 1.3em;
      font-weight: 700;
    }

    .mileage {
      font-style: italic;
    }
  }
}

.vdp-highlights {
  + .widget-cta {
    margin: 20px 0 30px;
  }
}

.vdp-summary {
  h4 {
    font-family: "Lato", sans-serif;
    margin-bottom: 10px;
  }

  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid $borderColor;
}

.vdp {
  .valet_listing_features {
    width: 100%;
  }

  @media (max-width: 991px) {
    aside {
      margin-top: 10px;
    }

    .widget-cta + .widget-text {
      margin-top: 0;
    }
  }
}
