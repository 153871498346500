#landing2 {
  h1 {
    line-height: 1.2em;
  }

  .thumbnail {
    padding-bottom: 75%;
    background-size: cover;
    background-position: center;
  }

  @media (min-width: 992px) {
    .thumbnail {
      margin-top: -50px;
      max-width: 110%;
      width: 110%;
    }
  }
}
