.slider-container {
  width: 300px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.slider-container .back-bar {
  height: 10px;
  position: relative;
}
.slider-container .back-bar .selected-bar {
  position: absolute;
  height: 100%;
}
.slider-container .back-bar .pointer {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: red;
  cursor: col-resize;
  opacity: 1;
  z-index: 2;
}
.slider-container .back-bar .pointer.last-active {
  z-index: 3;
}
.slider-container .back-bar .pointer-label {
  position: absolute;
  top: -17px;
  font-size: 8px;
  background: white;
  white-space: nowrap;
  line-height: 1;
}
.slider-container .back-bar .focused {
  z-index: 10;
}
.slider-container .clickable-dummy {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.slider-container .scale {
  top: 2px;
  position: relative;
}
.slider-container .scale span {
  position: absolute;
  height: 5px;
  border-left: 1px solid #999;
  font-size: 0;
}
.slider-container .scale ins {
  font-size: 9px;
  text-decoration: none;
  position: absolute;
  left: 0;
  top: 5px;
  color: #999;
  line-height: 1;
}
.slider-container.slider-readonly .clickable-dummy,
.slider-container.slider-readonly .pointer {
  cursor: auto;
}
.theme-green .back-bar {
  height: 5px;
  border-radius: 2px;
  background-color: #eeeeee;
  background-color: #e7e7e7;
  background-image: -moz-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eeeeee), to(#dddddd));
  background-image: -webkit-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -o-linear-gradient(top, #eeeeee, #dddddd);
  background-image: linear-gradient(to bottom, #eeeeee, #dddddd);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeeeee', endColorstr='#ffdddddd', GradientType=0);
}
.theme-green .back-bar .selected-bar {
  border-radius: 2px;
  background-color: #a1fad0;
  background-image: -moz-linear-gradient(top, #bdfade, #76fabc);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#bdfade), to(#76fabc));
  background-image: -webkit-linear-gradient(top, #bdfade, #76fabc);
  background-image: -o-linear-gradient(top, #bdfade, #76fabc);
  background-image: linear-gradient(to bottom, #bdfade, #76fabc);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbdfade', endColorstr='#ff76fabc', GradientType=0);
}
.theme-green .back-bar .pointer {
  width: 14px;
  height: 14px;
  top: -5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #AAA;
  background-color: #e7e7e7;
  background-image: -moz-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eeeeee), to(#dddddd));
  background-image: -webkit-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -o-linear-gradient(top, #eeeeee, #dddddd);
  background-image: linear-gradient(to bottom, #eeeeee, #dddddd);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeeeee', endColorstr='#ffdddddd', GradientType=0);
}
.theme-green .back-bar .pointer-label {
  color: #999;
}
.theme-green .back-bar .focused {
  color: #333;
}
.theme-green .scale span {
  border-left: 1px solid #e5e5e5;
}
.theme-green .scale ins {
  color: #999;
}
.theme-blue .back-bar {
  height: 5px;
  border-radius: 2px;
  background-color: #eeeeee;
  background-color: #e7e7e7;
  background-image: -moz-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eeeeee), to(#dddddd));
  background-image: -webkit-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -o-linear-gradient(top, #eeeeee, #dddddd);
  background-image: linear-gradient(to bottom, #eeeeee, #dddddd);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeeeee', endColorstr='#ffdddddd', GradientType=0);
}
.theme-blue .back-bar .selected-bar {
  border-radius: 2px;
  background-color: #92c1f9;
  background-image: -moz-linear-gradient(top, #b1d1f9, #64a8f9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b1d1f9), to(#64a8f9));
  background-image: -webkit-linear-gradient(top, #b1d1f9, #64a8f9);
  background-image: -o-linear-gradient(top, #b1d1f9, #64a8f9);
  background-image: linear-gradient(to bottom, #b1d1f9, #64a8f9);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffb1d1f9', endColorstr='#ff64a8f9', GradientType=0);
}
.theme-blue .back-bar .pointer {
  width: 14px;
  height: 14px;
  top: -5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #AAA;
  background-color: #e7e7e7;
  background-image: -moz-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eeeeee), to(#dddddd));
  background-image: -webkit-linear-gradient(top, #eeeeee, #dddddd);
  background-image: -o-linear-gradient(top, #eeeeee, #dddddd);
  background-image: linear-gradient(to bottom, #eeeeee, #dddddd);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeeeee', endColorstr='#ffdddddd', GradientType=0);
}
.theme-blue .back-bar .pointer-label {
  color: #999;
}
.theme-blue .back-bar .focused {
  color: #333;
}
.theme-blue .scale span {
  border-left: 1px solid #e5e5e5;
}
.theme-blue .scale ins {
  color: #999;
}
