/** app color variables */
:root {
	--header-orange: #f6610c;
	width: 100%;
	min-width: fit-content;
}

@import 'font';
// @import "genus-original";
@import 'plugins/flexslider';
@import 'plugins/jquery.range.scss';
// @import "plugins/paroller";
@import 'vars';
@import 'preloader';
@import 'header';
@import 'main_menu';
@import 'site_intro';
@import 'breadcrumbs';
@import 'sections';
@import 'footer';
@import 'common';
@import 'helpers';
@import 'lists';
@import 'pagination';
@import 'widgets';
@import 'page';
@import 'buttons';
@import 'forms';
@import 'social_links';
@import 'list_view';
@import 'srp';
@import 'vdp';
@import 'totop';
@import 'animations';
@import 'dark_section';
@import 'color_section';
@import 'grey_section';
@import 'section_specific';
@import 'landing2';
@import '404';
@import 'internal_links';
@import 'make_page';
@import 'generation_page';
@import 'rating';
@import 'sitemap';
@import 'responsive';
@import 'copilot_compare';
@import 'lease';
@import 'pr-logos';
@import 'popular-makes';
@import "bar_steps_iterator";
@import "board";
@import "price-pulse";
@import "price-pulse-header";
@import "press";
@import "cms/cms_styles";
