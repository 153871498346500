#preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: $darkColoredColor;
  background-image: url("/img/CoPilot-logo_FINAL_296.png");
  background-position: center;
  background-repeat: no-repeat;
}
