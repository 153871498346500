#site_breadcrumbs {
  padding: 5px 0;

  &.ds {
    background-color: transparent;
  }

  .subpage_top & {
    background-color: rgba(#000, 0.1);
  }

  h1 {
    color: #fff;
    margin-bottom: 10px;
  }
}

.breadcrumbs {
  list-style: none;
  padding: 0;
  display: inline-flex;
  flex-wrap: wrap;
  line-height: 1.3em;

  > li {
    + li {
      &:before {
        color:#000;
        content: ">";
        margin: 0 10px;
      }
    }
  }

  a {
    text-decoration: none;
    color: #000;
    font-family: 'Geomanist';

    &:hover {
      color: #000;
    }
  }
}

#site_breadcrumbs.ds {
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  padding: 15px 25px;
}

#site_breadcrumbs.ds span {
    text-decoration: none;
    color: #000;
    font-family: 'Geomanist';
    font-weight:700;
}



