.cs {
  color: rgba(#fff, 0.85);
  background-color: $mainColor;

  &.color2 {
    background-color: $mainColor2;
  }

  &.gradient {
    background-image: radial-gradient(circle, rgba($mainColor2, 0.9), rgba($mainColor, 0.9));
  }

  h1, h2, h3, h4, h5, h6 {
    color: #fff;
  }

  strong {
    color: #fff;
    font-weight: 700;
  }

  .button {
    color: #fff;
    background-color: transparent;
    border-color: #fff;

    &:hover,
    &:focus,
    &:active {
      color: $mainColor;
      background-color: #fff;
    }
  }
}