.no-padding-please{
  padding: 0px !important;
}

.col-4-override{
  margin-left: 10px;
  flex: 0 0 25.3333%;
}


#main_menu {
  &.ml{
    display:inline-block;
    padding-right: 5px;
    padding-left: 20px;

    .navbar-toggler-icon{
      color: black;
    }
  }

  a {
    text-decoration: none;
    font-weight: 300;
    color: #fff;
    padding: 10px 25px;
    transition: all 0.3s ease 0s;

    &:focus {
      outline: none;
    }
  }

  .dropdown {
    overflow: hidden;

    &.show {
      overflow: visible;
    }
  }

  .dropdown-menu {
    background-color: transparent;
    border: none;
    margin-top: 0;

    .dropdown-item {
      padding: 5px 15px;

      a {
        padding: 0;
      }
    }
  }

  @media (min-width: 1200px) {
    .dropdown-menu {
      border-radius: 0;
      border-top: 4px solid $mainColor;
      background-color: #fff;
      box-shadow: 0 2px 2px 2px rgba(#000, 0.05);
      margin-top: 10px;
      opacity: 0;
      display: block;
      transition: all 0.2s ease 0s;

      .dropdown-item {
        padding: 0;

        a {
          padding: 10px 25px;
        }
      }

      a {
        color: $darkGreyColor;

        &:hover {
          color: $mainColor;
          background-color: $backgroundColor;
        }
      }

      &.show {
        margin-top: 0;
        opacity: 1;
      }
    }
  }

  .navbar-toggler {
    border: none;
    color: #fff;
    padding: 0;
    width: 40px;
    height: 40px;
    font-size: 1.05rem;

    .navbar-toggler-icon {
      position: relative;

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:focus {
      outline: none;
    }
  }
}

#main_menu_list {
  background-color: $ml_mainGray!important;

  > ul {
    > li {
      padding: 5px 15px;
      > a {
        display: inline-block;
        position: relative;
        padding: 0;
        color: #000;
        // text-transform: uppercase;
        letter-spacing: 0.05em;
        font-size: 16px;

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background-color: #000;
          width: 0;
          transition: width 0.3s ease, background 0.3s ease;
        }
      }

      &.show > a,
      > a:hover {
        &:before {
          width: 100%;
        }
      }

      @media (min-width: 1200px) {
        padding: 15px;

        > a {
          display: block;
        }
      }

      @media (min-width: 1400px) {
        padding: 20px 15px;
      }
    }
  }

  @media (max-width: 1199px) {
    position: absolute;
    top: calc(100% + 9px);
    background-color: #3f3f3f;
    right: -15px;
    width: 100vw;
    z-index: 10000;
  }

  &.ml-bar{
    @media (max-width: 1199px) {
      left: 0px;
      top: calc(100% + 7px);
      right: unset;
    }


  }

}

#site-logo{
  // display:inline-block;
  // vertical-align: top;
  position: absolute;
  top: 6px;
  left: 80px;


  a{
    text-decoration: none;
  }

  a > .copilot-logo-svg {
    background-image: url(/svg/copilot-logo-orange.svg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 30px;
    width: 120px;
    display: inline-block;
    position: relative;
    vertical-align: text-top;
  }

}
