.button {
  display: inline-block;
  line-height: 1em;
  padding: 15px 28px 12px;
  color: #fff;
  font-family: "Roboto";
  text-transform: uppercase;
  letter-spacing: 0.1em;
  // font-weight: 300;
  background-color: $mainColor;
  border-radius: 25px;
  text-decoration: none;
  border: 2px solid $mainColor;
  min-width: 160px;
  transition: all 0.3s linear 0s;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    border-color: $mainColor2;
    background-color: $mainColor2;
    text-decoration: none;
    transition: all 0.05s ease 0s;
  }

  @media (min-width: 1200px) {
    &.big {
      padding: 20px 38px;
    }
  }

  &.color2 {
    background-color: $mainColor2;
    border-color: $mainColor2;

    &:hover,
    &:focus,
    &:active {
      border-color: $mainColor;
      background-color: $mainColor;
    }
  }
}


.ml-button-container {
  display: flex;
  justify-content: start;

  height: 36px;

  border-radius: 4px;
  border: solid 1px #f6610c;

  @media (min-width: 768px){
    justify-content: center;
  }

  @media (min-width: 1300px){
    justify-content: flex-end;
  }

  .ml-button {
    display: inline-block;
    padding: 0px 0px;
    width: 100%;

    @media (min-width: 768px){
      padding: 0px 8px;
    }



    letter-spacing: 0.1em;
    background-color: $ml_mainWhite;
    text-decoration: none;
    border: 2px solid $ml_mainWhite;
    color: $ml_mainOrange;
    transition: all 0.3s linear 0s;

    min-width: 94px;
    border-radius: 4px;

    font-family: "Geomanist";
    font-size: 14px;
    line-height: 1.43;

    a{
      text-decoration: none;

      &.pp{
        font-family: Geomanist-book;
        font-size: 14px;
        letter-spacing: normal;
      }
    }

    &:hover,
    &:focus,
    &:active {
      a {
        color: $ml_mainWhite;
      }
      color: $ml_mainWhite;
      border-color: $ml_mainOrange;
      background-color: $ml_mainOrange;
      text-decoration: none;
      transition: all 0.05s ease 0s;
    }

    @media (min-width: 1200px) {
      &.big {
        padding: 20px 38px;
        border-radius: 30px;
      }
    }


  }
}